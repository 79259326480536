import { Header } from "../../components/layout/header/header";
import { BinManagementTable } from "./bin-management-table/bin-management-table";
import { Footer } from "../../components/layout/footer/footer";
import { BinManagementType } from "../../interfaces/index";
import { useState, useEffect } from "react";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { DownloadOutlined } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import ImportBinManagementInstruction from "./bin-management-import-instructions-modal/bin-management-import-instructions-modal";

export function BinManagement(): JSX.Element {
  const headers = ["Name", "Site", "Barn", "Volume (hL)", "Inventory (MT)", "Date", "Update"];

  const [bins, setBins] = useState<BinManagementType[]>([]);
  const [loading, setLoading] = useState(false);

  const [importBinManagementOpen, setImportBinManagementOpen] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    fetchBinManagement();
  }, []);

  const refresh = (): void => {
    fetchBinManagement("Successfully refreshed!");
  };

  const fetchBinManagement = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<BinManagementType[]>({
        url: "/client/bins/management",
      });

      setBins(data);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const openImportBinManagementModal = (): void => {
    setImportBinManagementOpen(true);
  };

  return (
    <section className='bin-management'>
      <Header>
        <Tooltip title='Import Data'>
          <IconButton onClick={openImportBinManagementModal}>
            <DownloadOutlined fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <BinManagementTable headers={headers} rows={bins} fetchBinManagement={fetchBinManagement} loading={loading} />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh Bin Management' />

      <ImportBinManagementInstruction
        open={importBinManagementOpen}
        setOpen={setImportBinManagementOpen}
        fetchBinManagement={fetchBinManagement}
      />
    </section>
  );
}
