interface Props {
  className?: string;
  collapsed?: boolean;
  extension?: string;
}

export const Logo = ({ className, collapsed, extension }: Props): JSX.Element => {
  return (
    <img
      className={`logo ${className}`}
      src={`${process.env.PUBLIC_URL}/assets/images/${collapsed ? "logo-min" : "logo"}.${
        extension === "svg" ? "svg" : "png"
      }`}
      alt='Logo'
    />
  );
};
