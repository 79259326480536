import "./import-growth-curves-sequence-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks";
import { GrowthCurveSequence } from "../../../../../interfaces";
import { useSnackBar } from "../../../../../providers";
import { getFileFormData } from "../../../../../utils/append-file";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../../utils/download-file";

interface ImportGrowthCurveSequenceInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  importGrowthCurvesSequence: (growthCurveSequence: GrowthCurveSequence[]) => void;
}

const ImportGrowthCurveSequenceInstruction = ({
  open,
  setOpen,
  importGrowthCurvesSequence,
}: ImportGrowthCurveSequenceInstructionProps) => {
  const theme = useTheme();
  const { id } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const uploadGrowthCurveSequence = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const postData = async (): Promise<void> => {
        const data = await post<GrowthCurveSequence[]>({
          url: `/client/growth-curves/${id}/items/csv`,
          data: formData,
        });
        importGrowthCurvesSequence(data);

        openSnackBarMessage("Successfully imported!", "success");
        setOpen(false);
      };

      postData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setOpen(false);
      });
    }
  };

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: `/client/growth-curves/${id}/items/csv/template`,
        data: null,
      });
      downloadFile(data, "growth-curves-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Growth Curve Sequence Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>
            day, growth_kg_per_head_per_day, consumption_kg_per_head_per_day
          </span>
          <br />
          Where <span className='responsive-dialog__text-bold'>day</span> is the Day number for a given growth sequence
          step to be active, <span className='responsive-dialog__text-bold'>growth_kg_per_head_per_day</span> is the
          Growth Kg Per Head Per Day to use for the sequence step, and{" "}
          <span className='responsive-dialog__text-bold'>consumption_kg_per_head_per_day</span> is the Consumption Kgs
          Per Head Per Day for the sequence step.
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadGrowthCurveSequence} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportGrowthCurveSequenceInstruction;
