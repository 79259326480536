import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4a8d6a",
    },
    success: {
      main: "#4a8d6a",
    },
    error: {
      main: "#e25f5f",
    },
    warning: {
      main: "#f8b233",
    },
  },
  typography: {
    fontFamily: "Kumbh Sans, sans-serif",
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: "#000000DE",
        },
        select: {
          fontSize: "18px",
        },
      },
    },
  },
});
