import "./sidebar-item.scss";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandedMenu } from "./expanded-menu/expanded-menu";
import Grow from "@mui/material/Grow";
import { MenuIcons } from "./menu-icons/menu-icons";
import { NavLink } from "react-router-dom";
import { SideBarItem } from "../../../../interfaces";
import styled from "@emotion/styled";
import { useEffect } from "react";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})(({ theme, expand }: any) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme?.transitions?.create("transform", {
    duration: theme?.transitions?.duration?.shortest,
  }),
}));

export const SidebarItem = ({
  label,
  Icon,
  link,
  collapsed,
  expandable,
  expanded,
  setExpanded,
  expandableItems,
}: SideBarItem): JSX.Element => {
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [collapsed]);
  return (
    <>
      <NavLink
        className={({ isActive }) =>
          `sidebar__link ${isActive && "sidebar__link--active"} ${expanded && !isActive && "sidebar__link--hide"}`
        }
        to={link}
      >
        <Icon className='sidebar__link__icon' />
        {!collapsed && (
          <Grow in={!collapsed} {...(!collapsed ? { timeout: 1000 } : {})}>
            <div className='sidebar__link__container'>
              <span className='sidebar__link__label'>{label}</span>
              {expandable && (
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label='show more'
                >
                  <ExpandMoreIcon className='sidebar__link__icon' />
                </ExpandMore>
              )}
            </div>
          </Grow>
        )}
      </NavLink>
      {expanded && expandableItems && (
        <>
          <ExpandedMenu expandableItems={expandableItems} expanded={expanded} />
          <MenuIcons setExpanded={setExpanded} />
        </>
      )}
    </>
  );
};
