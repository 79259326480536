import { useEffect, useState } from "react";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { StyledTableCell } from "../table";
import { Payload } from "../../../interfaces";
import "./editable-multiple-select.scss";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

interface EditableTableCellProps<T> {
  row: T;
  data: string[];
  name: string;
  property: string;
  menuItems: Payload[] | null;
  valueProperty?: string;
  isUnsavedChanges?: boolean;
  onSaveChanges?: (row: T) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export function EditableMultipleSelectCell({
  row,
  data,
  name,
  property,
  valueProperty,
  menuItems,
  isUnsavedChanges,
  onSaveChanges,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
EditableTableCellProps<any>): JSX.Element {
  const [cellValue, setValue] = useState(data || []);
  const [isModified, setIsModified] = useState(row?.isAdded);

  useEffect(() => {
    setValue(data || []);
    if (!row?.isAdded) {
      if (isModified && isUnsavedChanges) {
        setIsModified(false);
      }
    }
  }, [data, isUnsavedChanges]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (onSaveChanges) {
      const {
        target: { value },
      } = event;
      const menus = menuItems?.filter((item) => {
        for (const cell of value) {
          if (item?.name === cell) {
            return item;
          }
        }
      });
      row[property] = menus?.map((item) => item.id);
      if (valueProperty) {
        row[valueProperty] = menus;
      }
      setIsModified(true);
      onSaveChanges(row);
      setValue(typeof value === "string" ? value.split(",") : value);
    }
  };
  return (
    <StyledTableCell align='center' className={`${isModified && "modified"} editable-field`}>
      <FormControl variant='standard' sx={{ m: 1, width: 200 }}>
        <InputLabel id='multiple-checkbox-label' className='label'>
          {name}
        </InputLabel>
        <Select
          multiple
          labelId='multiple-checkbox-label'
          id='multiple-checkbox'
          value={cellValue}
          renderValue={(selected) => selected?.join(", ")}
          input={<OutlinedInput label={name} />}
          onChange={(e) => handleChange(e)}
          MenuProps={MenuProps}
          IconComponent={ExpandMoreOutlinedIcon}
        >
          {menuItems &&
            menuItems?.map((item) => (
              <MenuItem value={item?.name} key={item.id}>
                <Checkbox checked={cellValue?.indexOf(item?.name) > -1} disabled={!onSaveChanges} />
                <ListItemText primary={item?.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </StyledTableCell>
  );
}
