/* eslint-disable @typescript-eslint/no-explicit-any */
interface ItemInterface {
  id: string | null;
  toDelete: boolean;
  isAdded: boolean;
}

export function handleAddChanged<T extends ItemInterface>(
  row: T | any,
  changedRows: T[] | any,
  isDeleted?: boolean,
  isModified?: boolean,
): T[] {
  let data = [...changedRows];

  const copyRow = { ...row };

  if (isModified) {
    data = handleNewItems(data, copyRow);
  }

  if (isDeleted) {
    copyRow.toDelete = true;
    if (copyRow?.isAdded) {
      data = data?.filter((item) => item?.id !== copyRow?.id);
    } else {
      const index = data?.findIndex((item) => item?.id === copyRow?.id);
      if (index !== -1 && index !== null && index !== undefined) {
        data = data?.filter((item) => item?.id !== copyRow?.id);
      }
      data.push(copyRow);
    }
  }

  return data;
}

export function handleNewItems<T extends ItemInterface>(data: T[], copyRow: T): T[] {
  if (!data.length) {
    data.push(copyRow);
  } else {
    data = data?.filter((site) => !!site?.id);
    data = data.map((site) => {
      if (site.id === copyRow.id) {
        site = copyRow;
      }

      return site;
    });
    if (data.filter((site) => site?.id === copyRow?.id)?.length === 0) {
      data.push(copyRow);
    }
  }

  return data;
}
