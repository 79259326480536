import { PermIdentity } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";

import { useState } from "react";
import { clearStorage, useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";

export const UserAccountMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { post } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (): void => {
    const postData = async (): Promise<void> => {
      await post({ url: "/client/auth/logout", data: null });

      clearStorage();
      window.location.reload();
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <div className='user-account'>
      <Button
        id='user-account-positioned-button'
        aria-controls={open ? "user-account-positioned-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <PermIdentity fontSize='medium' htmlColor='#737373' />
      </Button>
      <Menu
        id='user-account-positioned-menu'
        aria-labelledby='user-account-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
