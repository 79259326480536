import "./auth-wrapper.scss";

import { Logo } from "../ui/logo/logo";
import { ReactNode } from "react";

interface AuthWrapperProps {
  title: string;
  children: ReactNode;
}

export const AuthWrapper = ({ title, children }: AuthWrapperProps): JSX.Element => {
  return (
    <div className='auth'>
      <div className='auth__card'>
        <Logo extension='svg' className='auth__logo' />
        <p className='auth__title'>{title}</p>
        {children}
      </div>
      <h1 className='auth__header'>Welcome to Feed Planner</h1>
    </div>
  );
};
