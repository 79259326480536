import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { useAxios } from "../../../hooks";
import { FeedLoad, Mill, Order } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { handleAddChanged } from "../../../utils/check-add";
import FeedLoadModal from "./create-feed-load-modal/create-feed-load-modal";
import { FeedLoadTable } from "./feed-load-table/feed-load-table";

export function FeedLoadDetails(): JSX.Element {
  const headers = ["Feed Group", "Feed Type", "Site", "Barn", "Bin", "Amount", "Max Load Size", "Remove"];

  const [feedLoad, setFeedLoad] = useState<FeedLoad>();
  const [feedLoadOrders, setFeedLoadOrders] = useState<Order[]>([]);
  const [mills, setMills] = useState<Mill[]>([]);
  const [changedRows, setChangedRows] = useState<Order[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [feedLoadModalOpen, setFeedLoadModalOpen] = useState(false);

  const { id } = useParams();

  const { openSnackBarMessage } = useSnackBar();
  const { get, put, post } = useAxios();

  useEffect(() => {
    fetchMills();
    fetchFeedLoad();
  }, []);

  const refresh = (): void => {
    fetchFeedLoad("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedLoad("Successfully cancelled!");
  };

  const fetchFeedLoad = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedLoad>({ url: `/client/feed-orders/${id}` });

      setFeedLoad(data);
      setFeedLoadOrders(data.orders);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const fetchMills = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onAddChanged = (row: Order, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as Order[]);
  };

  const save = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedLoad>({ url: `/client/feed-orders/${id}/update`, data: { data: changedRows } });

      setIsReadyForSave(true);
      fetchFeedLoad("Successfully updated!");
      setChangedRows([]);
    };

    postData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const add = (): void => {
    setFeedLoadModalOpen(true);
  };

  const onUpdateScheduledDateAndMill = (dateTime: Date | null | undefined | string, millId: string): void => {
    const postData = async (): Promise<void> => {
      await put<FeedLoad>({ url: `/client/feed-orders/${id}`, data: { scheduledDate: dateTime, millId } });

      setIsReadyForSave(true);
      fetchFeedLoad("Successfully updated!");
      setChangedRows([]);
    };

    postData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  return (
    <div className='feed-load'>
      <Header />

      <FeedLoadTable
        headers={headers}
        rows={feedLoadOrders}
        mills={mills}
        feedLoad={feedLoad}
        isUnsavedChanges={isReadyForSave}
        setFeedLoadOrders={setFeedLoadOrders}
        onUpdateScheduledDateAndMill={onUpdateScheduledDateAndMill}
        onAddChanged={onAddChanged}
        loading={loading}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Feed Load'
        addButtonLabel='Add Feed Load'
        refreshButtonLabel='Refresh Feed Load'
        cancelButtonLabel='Discard changes'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>

      {feedLoadModalOpen && (
        <FeedLoadModal
          open={feedLoadModalOpen}
          setOpen={setFeedLoadModalOpen}
          bins={feedLoad?.barn?.bins}
          id={id}
          fetchFeedLoad={fetchFeedLoad}
        />
      )}
    </div>
  );
}
