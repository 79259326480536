import { Spinner } from "../../components/ui/spinner/spinner";
import { Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Link } from "react-router-dom";
import { AuthWrapper } from "../../components/auth-wrapper/auth-wrapper";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { useLocalStorage } from "../../hooks/use-local-storage.hook";

interface LoginProps {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  expirationDate: Date;
  roles: string[];
  expired: boolean;
}

export const Login = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [, setToken] = useLocalStorage("accessToken", "");
  const [, setRefreshToken] = useLocalStorage("refreshToken", "");
  const title = "Log Into Account";

  const { post } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const onChangeEmail = (value: string): void => {
    setEmail(value);
  };

  const onChangePassword = (value: string): void => {
    setPassword(value);
  };

  const login = (): void => {
    const auth = {
      authProvider: "INTERNAL",
      credentials: {
        id: email,
        secret: password,
      },
    };

    const isValid = isValidEmailAddress(email);

    if (isValid) {
      setIsValidEmail(false);
      setLoading(true);
      const postData = async (): Promise<void> => {
        const data = await post<LoginProps>({ url: "/client/auth/login", data: auth });

        openSnackBarMessage("Successfully authorized!", "success");
        setLoading(false);
        setToken(data.accessToken);
        setRefreshToken(data.refreshToken);
        window.location.reload();
      };

      postData().catch((error) => {
        setLoading(false);
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      });
    } else {
      setIsValidEmail(true);
    }
  };

  const isValidEmailAddress = (mail: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail,
    );
  };

  return (
    <AuthWrapper title={title}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl variant='outlined' size='small' className='auth__input'>
            <SendIcon fontSize='medium' className='auth__icon' />
            <InputLabel htmlFor='mail-field' className='auth__label'>
              Mail Address
            </InputLabel>
            <OutlinedInput
              id='mail-field'
              type='email'
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              error={isValidEmail}
              label='Mail Address'
              aria-describedby='component-error-text'
            />
            {isValidEmail && (
              <FormHelperText id='component-error-text' className='auth__helper'>
                Invalid Email Address
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant='outlined' size='small' className='auth__input' sx={{ mt: 2, mb: 2 }}>
            <LockOpenIcon fontSize='medium' className='auth__icon' />
            <InputLabel htmlFor='password-field' className='auth__label'>
              Password
            </InputLabel>
            <OutlinedInput
              id='password-field'
              type='password'
              value={password}
              error={!!password && password?.length < 4}
              onChange={(e) => onChangePassword(e.target.value)}
              label='Password'
              aria-describedby='component-error-text'
            />
            {!!password && password?.length < 4 && (
              <FormHelperText id='omponent-error-text' className='auth__helper'>
                Min Length is 4 characters
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Link to='/forgot-password' className='auth__navigation'>
        Forgot Password?
      </Link>
      <Button
        className='auth__button'
        variant='contained'
        color='success'
        onClick={login}
        disabled={(!!password && password?.length < 4) || !email || isLoading}
      >
        {isLoading ? <Spinner /> : "Log Into"}
      </Button>
    </AuthWrapper>
  );
};
