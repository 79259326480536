import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { SetupProgress } from "../system-setup";
import "./setup-steps.scss";

interface SetupStepsProps {
  steps: SetupProgress[];
}

export function SetupSteps({ steps }: SetupStepsProps): JSX.Element {
  const headers = ["Setup Step", "Status"];
  const [confirmOpen, setConfirmOpen] = useState(false);

  const navigate = useNavigate();

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const resetSystemSetup = (): void => {
    const postData = async (): Promise<void> => {
      await post<void>({ url: "/client/companies/setup/clear", data: null });

      openSnackBarMessage("Successfully updated!", "success");
    };

    postData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onConfirmReset = (): void => {
    setConfirmOpen(true);
  };

  const navigateToSetupMenu = (stepName: string) => {
    const navigateTo = stepName.replace(/\s/g, "-").toLocaleLowerCase();

    navigate(`/system-setup/${navigateTo}`);
  };

  return (
    <section className='system-setup-welcome'>
      <Button className='system-setup-welcome__reset' variant='contained' color='error' onClick={onConfirmReset}>
        Reset
      </Button>
      <Grid container spacing={2} className='system-setup-welcome__headers'>
        {headers &&
          headers.map((header) => (
            <Grid item xs={6} className='system-setup-welcome__header' key={header}>
              {header}
            </Grid>
          ))}
      </Grid>
      <div className='system-setup-welcome__body'>
        {steps &&
          steps.map((step) => (
            <Grid container spacing={2} className='system-setup-welcome__rows' key={step?.stepName}>
              <Grid
                item
                xs={6}
                className='system-setup-welcome__key'
                onClick={() => {
                  navigateToSetupMenu(step.stepName);
                }}
              >
                {step?.stepName}
              </Grid>
              <Grid item xs={6} className='system-setup-welcome__value'>
                {step?.status ? "Complete" : "Incomplete"}
              </Grid>
            </Grid>
          ))}
      </div>
      <ConfirmDialog title='Confirm Reset' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={resetSystemSetup}>
        Are you sure you want to confirm reset?
      </ConfirmDialog>
    </section>
  );
}
