import { Autocomplete, Checkbox, IconButton, TextField, Tooltip } from "@mui/material";
import "./feed-group-management-filters.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { Site, Barn } from "../../../interfaces";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface FeedGroupManagementFilterProps {
  sites: Site[];
  barns: Barn[];
  setBarns: Dispatch<SetStateAction<Barn[]>>;
  turnFilter: boolean;
  onTurnFilter: () => void;
  selectedSites: Site[];
  setSelectedSites: Dispatch<SetStateAction<Site[]>>;
  selectedBarns: Barn[];
  setSelectedBarns: Dispatch<SetStateAction<Barn[]>>;
  fetchFeedGroups: (message?: string, sites?: Site[], barns?: Barn[]) => void;
}

export function FeedGroupManagementFilter({
  sites,
  barns,
  setBarns,
  turnFilter,
  onTurnFilter,
  selectedSites,
  setSelectedSites,
  selectedBarns,
  setSelectedBarns,
  fetchFeedGroups,
}: FeedGroupManagementFilterProps): JSX.Element {
  const [filterLabel, setFilterLabel] = useState("Turn Filter On");

  const onChangeFilter = (): void => {
    onTurnFilter();
    setFilterLabel(!turnFilter ? "Turn Filter Off" : "Turn Filter On");
    if (turnFilter) {
      setSelectedSites([]);
      setSelectedBarns([]);
    }
  };

  const handleToGetUniqueBarns = (barnsData: Barn[]): Barn[] => {
    if (selectedBarns?.length) {
      const result = barnsData?.filter((o1) => selectedBarns?.some((o2) => o1?.id === o2?.id));
      if (result) {
        setSelectedBarns(result);
        return result;
      }
      return selectedBarns;
    }
    return selectedBarns;
  };

  const onChangeSelectSites = (event: SyntheticEvent<Element, Event>, values: Site[]): void => {
    setSelectedSites(values);
    const barnsData = values
      .map((site) => site.barns)
      .flat(1)
      .filter((barn) => !!barn);
    setBarns(barnsData);
    const currentSelectedBarns = handleToGetUniqueBarns(barnsData);
    if (turnFilter) {
      fetchFeedGroups("Successfully applied filter!", values, currentSelectedBarns);
    }
  };

  const onChangeSelectBarns = (event: SyntheticEvent<Element, Event>, values: Barn[]): void => {
    setSelectedBarns(values);
    if (turnFilter) {
      fetchFeedGroups("Successfully applied filter!", selectedSites, values);
    }
  };
  return (
    <div className='feed-group-management-filter'>
      <Autocomplete
        multiple
        id='filter-by-site'
        options={sites}
        value={selectedSites}
        disableCloseOnSelect
        onChange={(event, values) => onChangeSelectSites(event, values)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label='Filter By Site' placeholder='Filter By Site' />}
      />
      <Autocomplete
        multiple
        id='filter-by-barn'
        options={barns}
        value={selectedBarns}
        disableCloseOnSelect
        onChange={(event, values) => onChangeSelectBarns(event, values)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        style={{ width: 300, marginLeft: 32 }}
        renderInput={(params) => <TextField {...params} label='Filter By Barn' placeholder='Filter By Barn' />}
      />
      {turnFilter ? (
        <Tooltip title={filterLabel}>
          <IconButton className='feed-group-management-filter__button' onClick={onChangeFilter}>
            <FilterListIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={filterLabel}>
          <IconButton className='feed-group-management-filter__button' onClick={onChangeFilter}>
            <FilterListOffIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
