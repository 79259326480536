import "./layout.scss";

import { Sidebar } from "./sidebar/sidebar";
import { WithChildren } from "../../interfaces";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithChildren {}

export const Layout = ({ children }: Props): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className='layout'>
      <div className='layout__sidebar'>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      <div className={`layout__content ${collapsed && "layout__content--collapsed"}`}>{children}</div>
    </div>
  );
};
