import { WeeklySnapshotTable } from "../../../../components/table/weekly-snapshot-table/weekly-snapshot-table";
import { WeeklySnapshot } from "../../../../interfaces";
interface ManagementDashboardTableProps {
  headers: string[];
  rows: WeeklySnapshot[];
  loading: boolean;
}

export function ManagementDashboardTable({ headers, rows, loading }: ManagementDashboardTableProps): JSX.Element {
  return <WeeklySnapshotTable headers={headers} rows={rows} loading={loading} className='management-dashboard' />;
}
