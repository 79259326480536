import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { createContext, useEffect, useRef } from "react";

export const AxiosContext = createContext({} as AxiosInstance);

interface Props {
  config?: AxiosRequestConfig;
  requestInterceptors?: ((
    value: AxiosRequestConfig<unknown>,
  ) => AxiosRequestConfig<unknown> | Promise<AxiosRequestConfig<unknown>>)[];
  responseInterceptors?: ((
    value: AxiosResponse<unknown, unknown>,
  ) => AxiosResponse<unknown, unknown> | Promise<AxiosResponse<unknown, unknown>>)[];
  children: JSX.Element;
}

export const AxiosProvider = ({
  config = {},
  requestInterceptors = [],
  responseInterceptors = [],
  children,
}: Props) => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    requestInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(interceptor);
    });
    responseInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(interceptor);
    });
  }, []);

  return <AxiosContext.Provider value={instanceRef?.current}>{children}</AxiosContext.Provider>;
};

export default AxiosProvider;
