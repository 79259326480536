import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface ConfirmDialogProps {
  title: string;
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
}

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }: ConfirmDialogProps) => {
  const onClose = (): void => setOpen(false);
  const onNo = (): void => onClose();
  const onYes = (): void => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='confirm-dialog'>
      <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onNo} color='primary'>
          No
        </Button>
        <Button variant='contained' color='error' onClick={onYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
