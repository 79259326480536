import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./setup-sites-table.scss";
import { Mill, Site } from "../../../../interfaces";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";

interface SetupSitesTableProps {
  headers: string[];
  rows: Site[];
  loading: boolean;
  setSites: Dispatch<SetStateAction<Site[]>>;
  onAddChanged: (row: Site, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function SetupSitesTable({
  headers,
  rows,
  loading,
  setSites,
  onAddChanged,
  isUnsavedChanges,
}: SetupSitesTableProps): JSX.Element {
  const [mills, setMills] = useState<Mill[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    fetchMills();
  }, []);

  const fetchMills = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: Site): void => {
    const data = [...rows];
    data.map((site) => {
      if (site.id === row.id) {
        site = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setSites(data);
  };

  const removeSite = (row: Site): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((site) => site.id !== copyRow?.id);
      setSites(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='setup-sites-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row?.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row?.loadSize}
              property='loadSize'
              name='Load Size'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />

            <EditableSelectCell
              row={row}
              name='Mills'
              data={row?.millId}
              property='millId'
              menuItems={mills}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button variant='contained' color='error' startIcon={<CloseOutlined />} onClick={() => removeSite(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
