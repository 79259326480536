import { Spinner } from "../../components/ui/spinner/spinner";
import { Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { AuthWrapper } from "../../components/auth-wrapper/auth-wrapper";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";

export const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const title = "Reset Password";

  const { post } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const onChangeEmail = (value: string): void => {
    setEmail(value);
  };

  const login = (): void => {
    const isValid = isValidEmailAddress(email);

    if (isValid) {
      setIsValidEmail(false);
      setLoading(true);
      const postData = async (): Promise<void> => {
        await post({ url: `/client/auth/forgot-password?email=${email}`, data: null });

        openSnackBarMessage("Successfully sent recovery link, please check email!", "success");
        setLoading(false);
      };

      postData().catch((error) => {
        setLoading(false);
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      });
    } else {
      setIsValidEmail(true);
    }
  };

  const isValidEmailAddress = (mail: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail,
    );
  };

  return (
    <AuthWrapper title={title}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl variant='outlined' size='small' className='auth__input' sx={{ mb: 3 }}>
            <SendIcon fontSize='medium' className='auth__icon' />
            <InputLabel htmlFor='mail-field' className='auth__label'>
              Mail Address
            </InputLabel>
            <OutlinedInput
              id='mail-field'
              type='email'
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              error={isValidEmail}
              label='Mail Address'
              aria-describedby='component-error-text'
            />
            {isValidEmail && (
              <FormHelperText id='component-error-text' className='auth__helper'>
                Invalid Email Address
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Link to='/login' className='auth__navigation'>
        Back To Login?
      </Link>
      <Button
        className='auth__button'
        variant='contained'
        color='success'
        onClick={login}
        disabled={!email || isLoading}
      >
        {isLoading ? <Spinner /> : "Send"}
      </Button>
    </AuthWrapper>
  );
};
