import { Header } from "../../components/layout/header/header";
import { Footer } from "../../components/layout/footer/footer";
import { useEffect, useState } from "react";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { FeedOrder } from "../../interfaces";
import { FeedOrdersTable } from "./feed-orders-table/feed-orders-table";
import { DownloadOutlined } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import { downloadFile } from "../../utils/download-file";
import { handleAddChanged } from "../../utils/check-add";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";

export function FeedOrders(): JSX.Element {
  const headers = [
    "Feed Group",
    "Feed Type",
    "Site",
    "Mill",
    "Barn",
    "Order Time",
    "Scheduled Time",
    "Amount (MT)",
    "Load #",
    "Status",
  ];

  const [feedOrders, setFeedOrders] = useState<FeedOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [changedRows, setChangedRows] = useState<FeedOrder[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchFeedOrders();
  }, []);

  const refresh = (): void => {
    fetchFeedOrders("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedOrders("Successfully cancelled!");
  };

  const fetchFeedOrders = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedOrder[]>({
        url: "/client/feed-orders",
      });

      setFeedOrders(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const onAddChanged = (row: FeedOrder, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setChangedRows(data as any[]);
  };

  const exportFeedOrders = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-orders/export",
        data: null,
      });
      downloadFile(data, "feed-orders.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  const save = (): void => {
    const data = changedRows.map((item) => ({ id: item.id, status: item.status }));
    const postData = async (): Promise<void> => {
      await post<FeedOrder[]>({ url: "/client/feed-loads/feed-orders/update", data: { data } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchFeedOrders("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <section className='feed-orders-management'>
      <Header>
        <Tooltip title='Export Data'>
          <IconButton onClick={exportFeedOrders}>
            <DownloadOutlined fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <FeedOrdersTable
        headers={headers}
        rows={feedOrders}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
        onAddChanged={onAddChanged}
        setFeedOrders={setFeedOrders}
      />

      <Footer
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        refreshButtonLabel='Refresh Feed Orders'
        cancelButtonLabel='Discard changes'
        saveButtonLabel='Save Feed Orders'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </section>
  );
}
