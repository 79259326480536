import { CircularProgress } from "@mui/material";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./feed-group-detail-chart.scss";

interface FeedGroupDetailsChartProps {
  chartData: { date: string; weight?: number; count?: number }[] | undefined;
  type: "weight" | "count";
  loading?: boolean;
}

export function FeedGroupDetailsChart({ chartData, type, loading }: FeedGroupDetailsChartProps) {
  return (
    <div className={`feed-group-feed-detail-chart${type === "weight" ? "__first-element" : ""}`}>
      {loading ? (
        <CircularProgress color='success' className='feed-group-feed-detail-chart__spinner' />
      ) : (
        <>
          <p className='feed-group-feed-detail-chart__chart-axis-names--x-axis'>Days</p>
          <p className='feed-group-feed-detail-chart__chart-axis-names--y-axis'>{type}</p>
          <ResponsiveContainer width={"95%"} height={420}>
            <LineChart data={chartData} margin={{ left: 40, bottom: 15 }}>
              <CartesianGrid />
              <XAxis dataKey={"date"} axisLine={false} tickMargin={20} />
              <YAxis dataKey={type} axisLine={false} tickMargin={20} />
              <Tooltip />
              <Legend />
              <Line type='linear' stroke='#4a8d6a' dot={false} dataKey={type} legendType={"none"} />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
}
