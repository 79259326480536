import "./feed-orders-modal.scss";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import { Order } from "../../../../../interfaces";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useSnackBar } from "../../../../../providers";
import { useAxios } from "../../../../../hooks";

interface FeedOrdersModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  orders: Order[];
  setOrders: Dispatch<SetStateAction<Order[]>>;
}

const FeedOrdersModal = ({ open, setOpen, orders, setOrders }: FeedOrdersModalProps) => {
  const [maxWidth] = useState<DialogProps["maxWidth"]>(orders?.length === 1 ? "sm" : "md");

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const handleClose = (): void => {
    setOpen(false);
  };

  const onConfirmFeedOrder = (order: Order): void => {
    const postData = async (): Promise<void> => {
      await post<Order>({
        url: `/client/feed-loads/${order.feedLoadId}/feed-orders/${order.feedOrderId}/confirm`,
      });
      const ordersData = [...orders];
      const orderIndex = ordersData.findIndex((orderItem) => orderItem.feedOrderId === order.feedOrderId);
      order.status = "ORDERED";
      ordersData[orderIndex] = order;
      setOrders(ordersData);
      openSnackBarMessage("Successfully confirmed!", "success");
    };
    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const onCancelFeedOrder = (order: Order): void => {
    const postData = async (): Promise<void> => {
      await post<Order>({
        url: `/client/feed-loads/${order.feedLoadId}/feed-orders/${order.feedOrderId}/cancel`,
      });
      const ordersData = [...orders];
      const orderIndex = ordersData.findIndex((orderItem) => orderItem.feedOrderId === order.feedOrderId);
      order.status = "CANCELLED";
      ordersData[orderIndex] = order;
      setOrders(ordersData);
      openSnackBarMessage("Successfully cancelled!", "success");
    };
    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Feed Orders
      </DialogTitle>

      <DialogContent>
        <div className='responsive-dialog__orders'>
          {orders.map((order, index) => (
            <div className='responsive-dialog__order' key={order.feedGroupId + index}>
              <p className='responsive-dialog__order-property'>Feed Order: {order?.feedOrder}</p>
              <p className='responsive-dialog__order-property'>Feed Type: {order?.feedType}</p>
              <p className='responsive-dialog__order-property'>Bin: {order?.bins?.join(", ")}</p>
              <p className='responsive-dialog__order-property'>
                Scheduled Day: {dayjs(order?.scheduledDate).format("YYYY-MM-DD")}
              </p>
              <p className='responsive-dialog__order-property'>
                Order Time: {dayjs(order?.orderTime).format("YYYY-MM-DD, HH:mm")}
              </p>
              <p className='responsive-dialog__order-property'>
                Load #: <Link to={`/feed-orders/feed-load/${order?.feedLoadId}`}>{order?.loadNumber}</Link>
              </p>
              <p className='responsive-dialog__order-property'>Amount (MT): {order?.amount}</p>
              <div className='responsive-dialog__order-status'>
                {(order?.status === "CANCELLED" || order?.status === "PENDING") && (
                  <Tooltip title='Confirm'>
                    <IconButton onClick={() => onConfirmFeedOrder(order)}>
                      <DoneIcon fontSize='medium' color='success' />
                    </IconButton>
                  </Tooltip>
                )}
                {(order?.status === "PENDING" || order?.status === "ORDERED") && (
                  <Tooltip title='Cancel'>
                    <IconButton onClick={() => onCancelFeedOrder(order)}>
                      <CancelIcon fontSize='medium' color='error' />
                    </IconButton>
                  </Tooltip>
                )}
                <p className='responsive-dialog__order-property responsive-dialog__order-property--no-space'>
                  {order?.status}
                </p>
              </div>
            </div>
          ))}
        </div>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FeedOrdersModal;
