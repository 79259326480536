import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import Button from "@mui/material/Button";
import "./user-admin-table.scss";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { User } from "../../../interfaces";
import { Dispatch, SetStateAction } from "react";
import { EditableTableCell } from "../../../components/table/editable-input-cell/editable-input-cell";
import { EditableTableAdminCell } from "./user-admin-table-cell/user-admin-table-cell";

interface UserAdminTableProps {
  headers: string[];
  rows: User[];
  isUnsavedChanges: boolean;
  setUsers: Dispatch<SetStateAction<User[]>>;
  onAddChanged: (row: User, isDeleted?: boolean, isModified?: boolean) => void;
  loading: boolean;
}

export function UserAdminTable({
  headers,
  rows,
  setUsers,
  onAddChanged,
  loading,
  isUnsavedChanges,
}: UserAdminTableProps): JSX.Element {
  const onSaveChanges = (row: User): void => {
    const data = [...rows];
    data.map((user) => {
      if (user.id === row.id) {
        user = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setUsers(data);
  };

  const removeUser = (row: User): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((user) => user.id !== copyRow?.id);
      setUsers(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='user-admin-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.email}
              property='email'
              name='Email'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableAdminCell row={row} data={row.isAdmin} onSaveChanges={onSaveChanges} />
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='error'
                endIcon={<CancelPresentationIcon />}
                onClick={() => removeUser(row)}
              >
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
