import { Button } from "@mui/material";

import { useEffect, useState } from "react";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import { Footer } from "../../../../components/layout/footer/footer";
import { Header } from "../../../../components/layout/header/header";
import { useAxios } from "../../../../hooks";
import { GrowthAdjustmentCondition } from "../../../../interfaces";
import { useSnackBar } from "../../../../providers";
import { generatedId } from "../../../../utils/generator-ids";
import { useNavigate, useParams } from "react-router-dom";
import { GrowthAdjustmentConditionsTable } from "./growth-adjustment-conditions-table/growth-adjustment-conditions-table";
import { handleSetupComplete } from "../../../../utils/setup-complete";
import { handleAddChanged } from "../../../../utils/check-add";

export function GrowthAdjustmentConditions(): JSX.Element {
  const headers = ["Condition Type", "Condition Configuration", "Remove"];

  const { id } = useParams();
  const [growthAdjustmentConditions, setGrowthAdjustmentConditions] = useState<GrowthAdjustmentCondition[]>([]);
  const [changedRows, setChangedRows] = useState<GrowthAdjustmentCondition[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchGrowthAdjustmentConditions();
  }, []);

  const refresh = (): void => {
    fetchGrowthAdjustmentConditions("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchGrowthAdjustmentConditions("Successfully cancelled!");
  };

  const fetchGrowthAdjustmentConditions = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthAdjustmentCondition[]>({
        url: `/client/growth-adjustment/${id}/conditions`,
      });

      setGrowthAdjustmentConditions(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: GrowthAdjustmentCondition, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as GrowthAdjustmentCondition[]);
  };

  const add = (): void => {
    const data = [...growthAdjustmentConditions];
    const growthAdjustmentConditionsData = [...changedRows];

    const growthAdjustmentCondition: GrowthAdjustmentCondition = {
      id: generatedId(36),
      subId: "",
      type: growthAdjustmentConditions[0]?.type || "DEFAULT_TYPE",
      configuration: growthAdjustmentConditions[0]?.configuration || "Configuration",
      toDelete: false,
      isAdded: true,
    };

    growthAdjustmentCondition.subId = growthAdjustmentCondition.id;
    data.push(growthAdjustmentCondition);
    growthAdjustmentConditionsData.push(growthAdjustmentCondition);

    setIsReadyForSave(false);
    setGrowthAdjustmentConditions(data);
    setChangedRows(growthAdjustmentConditionsData);
  };

  const save = (): void => {
    getFinalGrowthAdjustmentConditions();

    const postData = async (): Promise<void> => {
      await post<GrowthAdjustmentCondition[]>({
        url: `/client/growth-adjustment/${id}/conditions/update`,
        data: { data: changedRows },
      });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchGrowthAdjustmentConditions("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((growthAdjustmentCondition) => {
      if (growthAdjustmentCondition.isAdded && !growthAdjustmentCondition?.id) {
        growthAdjustmentCondition.id = growthAdjustmentCondition.subId;
      }

      return growthAdjustmentCondition;
    });

    setChangedRows(data);
  };

  const getFinalGrowthAdjustmentConditions = (): void => {
    let data = [...changedRows];
    data = data.map((growthAdjustmentCondition) => {
      if (growthAdjustmentCondition.isAdded) {
        growthAdjustmentCondition.id = null;
      }
      return growthAdjustmentCondition;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const handleGrowthAdjustmentsSetupComplete = (): void => {
    handleSetupComplete("GROWTH_ADJUSTMENTS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='feed-plan-sequence'>
      <Header>
        <Button variant='contained' color='primary' onClick={handleGrowthAdjustmentsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <GrowthAdjustmentConditionsTable
        headers={headers}
        growthAdjustmentConditions={growthAdjustmentConditions}
        setGrowthAdjustmentConditions={setGrowthAdjustmentConditions}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Feed Plan Sequence'
        addButtonLabel='Add Feed Plan Sequence'
        refreshButtonLabel='Refresh Feed Plan Sequence'
        cancelButtonLabel='Discard changes'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
