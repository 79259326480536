import { ReactElement, useEffect, useState } from "react";
import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { useAxios } from "../../hooks";
import { Mill, ProductionSequenceProps } from "../../interfaces";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useSnackBar } from "../../providers";
import { Button, Typography } from "@mui/material";
import { ProductionSequenceTable } from "./production-sequence-table/production-sequence-table";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import "./production-sequence.scss";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function ProductionSequence(): JSX.Element {
  const headers = [
    "Sequence",
    "Feed Group",
    "Feed Type",
    "Site",
    "Barn",
    "Scheduled Time",
    "Amount (MT)",
    "Load #",
    "Status",
    "Days Remaining Inventory",
  ];

  const [productionSequence, setProductionSequence] = useState<ProductionSequenceProps[]>([]);
  const [mills, setMills] = useState<Mill[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [changedRows, setChangedRows] = useState<ProductionSequenceProps[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post, patch } = useAxios();

  useEffect(() => {
    fetchMills();
  }, []);

  const refresh = (): void => {
    fetchMills();
    fetchProductionSequence("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchMills();
    fetchProductionSequence("Successfully cancelled!");
  };

  const fetchMills = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
      if (data?.length && data[activeTab]?.id) {
        fetchProductionSequence(data[activeTab].id || "");
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchProductionSequence = (millId: string, message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      let data = await get<ProductionSequenceProps[]>({ url: `/client/feed-orders/search?millId=${millId}` });
      data = data.map((item, index) => {
        item.number = index + 1;
        return item;
      });
      setProductionSequence(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const a11yProps = (index: number): { id: string; "aria-controls": string } => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TabPanel = (props: TabPanelProps): ReactElement<any, any> => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
    if (mills[newValue]) {
      fetchProductionSequence(mills[newValue].id || "");
    }
  };

  const confirmFeedOrders = (): void => {
    setLoading(true);
    const millId = mills[activeTab].id;
    if (millId) {
      const fetchData = async (): Promise<void> => {
        await post<ProductionSequenceProps[]>({ url: `/client/feed-orders/confirm?millId=${millId}` });
        openSnackBarMessage("Successfully confirmed!", "success");
        fetchProductionSequence(millId);
        setLoading(false);
      };

      fetchData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setLoading(false);
      });
    }
  };

  const cancelFeedOrders = (): void => {
    setLoading(true);
    const millId = mills[activeTab].id;
    if (millId) {
      const fetchData = async (): Promise<void> => {
        await post<ProductionSequenceProps[]>({ url: `/client/feed-orders/cancel?millId=${millId}` });
        openSnackBarMessage("Successfully cancelled!", "success");
        fetchProductionSequence(millId);
        setLoading(false);
      };

      fetchData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setLoading(false);
      });
    }
  };

  const getUniqueIds = (value: string, index: number, self: string[]): boolean => {
    return self.indexOf(value) === index;
  };

  const save = (): void => {
    const sequenceIds = productionSequence.map((row) => row.id || "");
    const updatedIds = changedRows.map((row) => row.id || "");
    let data = sequenceIds.concat(updatedIds);
    data = data.filter((value: string, index: number, self: string[]) => getUniqueIds(value, index, self));
    const millId = mills[activeTab].id;
    if (data?.length && millId) {
      const postData = async (): Promise<void> => {
        await patch<ProductionSequenceProps[]>({
          url: "/client/feed-loads/feed-orders/sequence",
          data: { data },
        });
        openSnackBarMessage("Successfully saved!", "success");
        fetchProductionSequence(millId);
        setIsReadyForSave(true);
        setChangedRows([]);
        setLoading(false);
      };

      postData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setChangedRows([]);
        setLoading(false);
      });
    }
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  return (
    <div className='production-sequence'>
      <Header />

      <Box sx={{ width: "100%" }} className='production-sequence__tabs'>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleChange} aria-label='mills tabs'>
            {mills?.map((mill, index) => (
              <Tab label={mill.name} key={mill.id} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {mills?.map((mill, index) => (
          <TabPanel key={mill.id} value={activeTab} index={index}>
            {productionSequence && (
              <div className='production-sequence__actions'>
                <Button variant='contained' color='success' endIcon={<CheckIcon />} onClick={() => confirmFeedOrders()}>
                  Confirm
                </Button>
                <Button variant='contained' color='error' endIcon={<CancelIcon />} onClick={() => cancelFeedOrders()}>
                  Cancel
                </Button>
              </div>
            )}
            <ProductionSequenceTable
              headers={headers}
              rows={productionSequence}
              setProductionSequence={setProductionSequence}
              setIsReadyForSave={setIsReadyForSave}
              loading={loading}
              isUnsavedChanges={isReadyForSave}
              changedRows={changedRows}
              setChangedRows={setChangedRows}
            />
          </TabPanel>
        ))}
      </Box>

      <Footer
        onRefresh={refresh}
        onSave={save}
        refreshButtonLabel='Refresh Production Sequence'
        saveButtonLabel='Save Production Sequence'
        cancelButtonLabel='Discard changes'
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
