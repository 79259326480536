import "./breadcrumb.scss";

import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import { routes } from "../../../config/breadcrumb-links";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export function BreadCrumb(): JSX.Element {
  const location = useLocation();
  const [relatedLocation, setRelatedLocation] = useState(location.pathname);
  const { id } = useParams();
  const navigate = useNavigate();

  const onHandleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const navigateToPage = (link: string): void => {
    if (link.includes(":id") && id) {
      link = link.replace(":id", id);
    } else {
      navigate(link);
    }
  };

  const classes = {
    ol: "",
    li: "",
  };

  useEffect(() => {
    const locationMap = location.pathname.split("/");
    locationMap.pop();
    setRelatedLocation(locationMap.join("/"));
  }, []);

  return (
    <div role='presentation' onClick={onHandleClick}>
      <Breadcrumbs aria-label='breadcrumb' className='breadcrumbs' classes={classes}>
        {routes &&
          routes.map(
            (route) =>
              (route.to === location.pathname || relatedLocation === route?.relatedTo) &&
              route.labels.map((label) => (
                <Link
                  className='breadcrumb__item'
                  href={label.to}
                  key={label.label}
                  onClick={() => navigateToPage(label.to)}
                >
                  {label.label}
                  <route.icon sx={{ ml: 0.5 }} fontSize='large' />
                </Link>
              )),
          )}
      </Breadcrumbs>
    </div>
  );
}
