import "./feed-forecast-content.scss";
import { Grid } from "@mui/material";
import { FeedForecastFilter } from "./feed-forecast-filter/feed-forecast-filter";
import { useEffect, useState } from "react";
import { FeedForecastWeeklySnapshotTable } from "./feed-forecast-weekly-snapshot-table/feed-forecast-weekly-snapshot-table";
import { FeedForecastFeedGroupTable } from "./feed-forecast-feed-group-table/feed-forecast-feed-group-table";
import { FeedForecast, FeedGroupForecastData, Mill, Site, WeeklySnapshot } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { useAxios } from "../../../hooks";
import { generateWeeklySnapshotTable } from "../../../utils/weekly-snapshot-table";
import { downloadFile } from "../../../utils/download-file";

export function FeedForecastContent(): JSX.Element {
  const [weeklySnapshotHeaders, setWeeklySnapshotHeaders] = useState<string[]>([]);
  const [feedGroupHeaders, setFeedGroupHeaders] = useState<string[]>([]);
  const [weeklySnapshot, setWeeklySnapshot] = useState<WeeklySnapshot[]>([]);
  const [feedGroup, setFeedGroup] = useState<FeedGroupForecastData[]>([]);
  const [sites, setSites] = useState<Site[] | []>([]);
  const [mills, setMills] = useState<Mill[] | []>([]);
  const [selectedSites, setSelectedSites] = useState<Site[] | []>([]);
  const [selectedMills, setSelectedMills] = useState<Mill[] | []>([]);
  const [turnFilter, setTurnFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post, delete: deleteRequest } = useAxios();

  useEffect(() => {
    fetchFeedForecast();
    fetchSites();
    fetchMills();
  }, []);

  const fetchFeedForecast = (message?: string, sites?: Site[], mills?: Mill[]): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await post<FeedForecast>({
        url: "/client/feed-groups/forecast",
        data: { sites: getSiteIds(sites), mills: getMillIds(mills) },
      });

      const { headers, weeklySnapshotData } = generateWeeklySnapshotTable(data.weeklySnapshotData);
      setWeeklySnapshot(weeklySnapshotData);
      setWeeklySnapshotHeaders(headers);
      generateFeedOrdersTable(data.feedGroupForecastData, data.weeklySnapshotData.dates);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const generateFeedOrdersTable = (feedGroupData: FeedGroupForecastData[], dates: string[]): void => {
    let headers = ["Feed Group", "Site", "Barn", "Bins", "Cap.", "Animals"];
    headers = headers.concat(dates);

    setFeedGroupHeaders(headers);
    setFeedGroup(feedGroupData);
  };

  const getSiteIds = (sites?: Site[]): (string | null)[] => {
    if (!selectedSites?.length && !sites?.length) {
      return [];
    }

    return sites ? sites.map((site) => site?.id) : selectedSites.map((site) => site?.id);
  };

  const getMillIds = (mills?: Mill[]): (string | null)[] => {
    if (!selectedMills?.length && !mills?.length) {
      return [];
    }

    return mills ? mills.map((mill) => mill?.id) : selectedMills.map((mill) => mill?.id);
  };

  const fetchSites = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchMills = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const generateFeedOrders = (): void => {
    const fetchData = async (): Promise<void> => {
      await post<null>({
        url: "/client/feed-orders/single-algorithm",
        data: { data: getSiteIds() },
      });
      openSnackBarMessage("Successfully generated feed orders!", "success");
      fetchFeedForecast();
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onTurnFilter = (): void => {
    setTurnFilter(!turnFilter);
    const message = `Successfully turned filter ${turnFilter ? "off" : "on"}!`;
    fetchFeedForecast(message, turnFilter ? [] : undefined, turnFilter ? [] : undefined);
  };

  const exportForecastData = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-groups/forecast/export",
        data: { data: getSiteIds() },
      });
      downloadFile(data, "forecast.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  const exportFeedOrdersData = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-orders/export",
        data: null,
      });
      downloadFile(data, "feed-orders.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  const deleteFeedOrders = (): void => {
    const deleteData = async (): Promise<void> => {
      await deleteRequest<null>({
        url: "/client/feed-orders",
        data: { data: getSiteIds() },
      });
      openSnackBarMessage("Successfully deleted feed orders!", "success");
      fetchFeedForecast();
    };

    deleteData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  return (
    <div className='feed-forecast-content'>
      <FeedForecastFilter
        options={sites}
        mills={mills}
        turnFilter={turnFilter}
        onTurnFilter={onTurnFilter}
        selectedSites={selectedSites}
        selectedMills={selectedMills}
        setSelectedSites={setSelectedSites}
        setSelectedMills={setSelectedMills}
        generateFeedOrders={generateFeedOrders}
        exportForecastData={exportForecastData}
        exportFeedOrdersData={exportFeedOrdersData}
        deleteFeedOrders={deleteFeedOrders}
        fetchFeedForecast={fetchFeedForecast}
      />
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={8}>
          <FeedForecastWeeklySnapshotTable
            headers={weeklySnapshotHeaders}
            rows={weeklySnapshot}
            fetchData={fetchFeedForecast}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <FeedForecastFeedGroupTable headers={feedGroupHeaders} rows={feedGroup} loading={loading} />
        </Grid>
      </Grid>
    </div>
  );
}
