import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { menuItems } from "../../../../../config/sidebar-menu-icons";
import "./menu-icons.scss";

interface MenuIconsProps {
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

export const MenuIcons = ({ setExpanded }: MenuIconsProps): JSX.Element => {
  const toggleExpanded = (): void => {
    setExpanded(false);
  };
  return (
    <div className='menu-icons'>
      {menuItems &&
        menuItems.map((item, index) => (
          <Link to={item.link} key={index} className='menu-icons__icon' onClick={toggleExpanded}>
            <item.Icon fontSize='medium' />
          </Link>
        ))}
    </div>
  );
};
