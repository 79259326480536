import { useEffect, useState } from "react";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { useAxios } from "../../hooks";
import { User } from "../../interfaces";
import { useSnackBar } from "../../providers";
import { handleAddChanged } from "../../utils/check-add";
import { generatedId } from "../../utils/generator-ids";
import { UserAdminTable } from "./user-admin-table/user-admin-table";

export function UserAdmin(): JSX.Element {
  const headers = ["Name", "Email", "Admin", "Remove"];

  const [users, setUsers] = useState<User[]>([]);
  const [changedRows, setChangedRows] = useState<User[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, put } = useAxios();

  useEffect(() => {
    fetchUsers();
  }, []);

  const refresh = (): void => {
    fetchUsers("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchUsers("Successfully cancelled!");
  };

  const fetchUsers = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<User[]>({ url: "/client/users" });

      setUsers(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: User, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as User[]);
  };

  const add = (): void => {
    const data = [...users];
    const userData = [...changedRows];

    const user: User = {
      id: generatedId(36),
      subId: "",
      email: "email@example.com",
      name: "User",
      isAdmin: false,
      toDelete: false,
      isAdded: true,
    };

    user.subId = user.id;
    data.push(user);
    userData.push(user);

    setIsReadyForSave(false);
    setUsers(data);
    setChangedRows(userData);
  };

  const getFinalSetupUsers = (): void => {
    let data = [...changedRows];

    data = data.map((users) => {
      if (users.isAdded) {
        users.id = null;
      }

      return users;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupUsers();

    const postData = async (): Promise<void> => {
      await put<User[]>({ url: "/client/users", data: { data: changedRows } });

      setIsReadyForSave(true);
      fetchUsers("Successfully updated!");
      setChangedRows([]);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((user) => {
      if (user.isAdded && !user?.id) {
        user.id = user.subId;
      }

      return user;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  return (
    <div className='user-admin'>
      <Header />

      <UserAdminTable
        headers={headers}
        rows={users}
        isUnsavedChanges={isReadyForSave}
        setUsers={setUsers}
        onAddChanged={onAddChanged}
        loading={loading}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save User Admin'
        addButtonLabel='Add User Admin'
        refreshButtonLabel='Refresh User Admin'
        cancelButtonLabel='Discard changes'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
