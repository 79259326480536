import "./import-bin-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAxios } from "../../../../hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackBar } from "../../../../providers";
import { useTheme } from "@mui/material/styles";
import { SetupBins } from "../setup-bins-table/setup-bins-table";
import { getFileFormData } from "../../../../utils/append-file";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../utils/download-file";

interface ImportBinInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  importBins: (bins: SetupBins[]) => void;
}

const ImportBinInstruction = ({ open, setOpen, importBins }: ImportBinInstructionProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const uploadBins = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const postData = async (): Promise<void> => {
        const data = await post<SetupBins[]>({
          url: "/client/bins/csv",
          data: formData,
        });
        importBins(data);

        openSnackBarMessage("Successfully imported!", "success");
        setOpen(false);
      };

      postData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setOpen(false);
      });
    }
  };

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/bins/csv/template",
        data: null,
      });
      downloadFile(data, "bins-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Bin Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>name, site, barn, volume, inventory</span>
          <br />
          Where <span className='responsive-dialog__text-bold'>name</span> is the Bin Name,{" "}
          <span className='responsive-dialog__text-bold'>site</span> is the associated Site name,{" "}
          <span className='responsive-dialog__text-bold'>barn</span> is the associated Barn name,{" "}
          <span className='responsive-dialog__text-bold'>volume</span> is the total possible amount of volume (in hL)
          that can be stored in the bin, and <span className='responsive-dialog__text-bold'>inventory</span> is the{" "}
          current amount of total inventory (in Metric Tons MT) currently stored in the bin.
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadBins} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportBinInstruction;
