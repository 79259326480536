import { WeeklySnapshotData, WeeklySnapshot } from "../interfaces";

export const generateWeeklySnapshotTable = (
  weeklySnapshotResponse: WeeklySnapshotData,
): { headers: string[]; weeklySnapshotData: WeeklySnapshot[] } => {
  let headers = ["Weekly Snapshot"];
  headers = headers.concat(weeklySnapshotResponse.dates);

  const weeklySnapshotData: WeeklySnapshot[] = [];
  const columnHeaders = ["Orders", "Target", "% of Capacity", "Days of Feed"];
  for (let index = 0; index <= 4; index++) {
    switch (index) {
      case 0:
        getWeeklySnapshotTableRow(weeklySnapshotData, weeklySnapshotResponse, index, columnHeaders, "orders");
        break;
      case 1:
        getWeeklySnapshotTableRow(weeklySnapshotData, weeklySnapshotResponse, index, columnHeaders, "targets");
        break;
      case 2:
        getWeeklySnapshotTableRow(
          weeklySnapshotData,
          weeklySnapshotResponse,
          index,
          columnHeaders,
          "percentOfCapacity",
        );
        break;
      case 3:
        getWeeklySnapshotTableRow(weeklySnapshotData, weeklySnapshotResponse, index, columnHeaders, "daysOfFeed");
        break;
      default:
        break;
    }
  }

  return { headers, weeklySnapshotData };
};

const getWeeklySnapshotTableRow = (
  weeklySnapshotData: WeeklySnapshot[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weeklySnapshotResponse: WeeklySnapshotData | any,
  index: number,
  columnHeaders: string[],
  property: string,
) => {
  weeklySnapshotData[index] = {
    name: columnHeaders[index],
    data: weeklySnapshotResponse[property],
  };
};
