import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { SetupBinesTable, SetupBins } from "./setup-bins-table/setup-bins-table";
import { useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import ImportBinInstruction from "./import-bin-instructions-modal/import-bin-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";

export function SetupBines(): JSX.Element {
  const headers = ["Name", "Site", "Barn", "Volume (hL)", "Inventory (MT)", "Remove"];

  const [bins, setBins] = useState<SetupBins[]>([]);
  const [changedRows, setChangedRows] = useState<SetupBins[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importBinsOpen, setImportBinsOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchBins();
  }, []);

  const refresh = (): void => {
    fetchBins("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchBins("Successfully cancelled!");
  };

  const fetchBins = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<SetupBins[]>({ url: "/client/bins" });

      setBins(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: SetupBins, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as SetupBins[]);
  };

  const add = (): void => {
    const data = [...bins];
    const binsData = [...changedRows];

    const bin: SetupBins = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      site: data[0]?.site,
      siteId: data[0]?.siteId,
      barnId: data[0]?.barnId,
      barn: data[0]?.barn,
      volume: 0,
      inventory: 0,
      feedTypeId: data[0]?.feedTypeId,
      feedType: data[0]?.feedType,
      date: data[0]?.date,
      isAdded: true,
      toDelete: false,
    };

    bin.subId = bin.id;
    data.push(bin);
    binsData.push(bin);

    setIsReadyForSave(false);
    setBins(data);
    setChangedRows(binsData);
  };

  const importBins = (importedBins: SetupBins[]): void => {
    let data = [...bins];
    let binsData = [...changedRows];
    importedBins = importedBins.map((bin) => {
      bin.id = generatedId(36);
      bin.subId = bin.id;
      bin.isAdded = true;
      bin.toDelete = false;
      return bin;
    });

    data = data.concat(importedBins);
    binsData = binsData.concat(importedBins);

    setIsReadyForSave(false);
    setBins(data);
    setChangedRows(binsData);
  };

  const getFinalSetupBins = (): void => {
    let data = [...changedRows];

    data = data.map((bin) => {
      if (bin.isAdded) {
        bin.id = null;
      }

      return bin;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupBins();

    const postData = async (): Promise<void> => {
      await post<SetupBins[]>({ url: "/client/bins/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchBins("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((bin) => {
      if (bin.isAdded && !bin?.id) {
        bin.id = bin.subId;
      }

      return bin;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportBinsModal = (): void => {
    setImportBinsOpen(true);
  };

  const handleBinsSetupComplete = (): void => {
    handleSetupComplete("BINS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='setup-bines'>
      <Header>
        <Tooltip title='Import System Bins'>
          <IconButton onClick={openImportBinsModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleBinsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupBinesTable
        headers={headers}
        rows={bins}
        setBins={setBins}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Bines'
        addButtonLabel='Add Setup Bines'
        refreshButtonLabel='Refresh Setup Bines'
        cancelButtonLabel='Discard changes'
      />

      <ImportBinInstruction open={importBinsOpen} setOpen={setImportBinsOpen} importBins={importBins} />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
