import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../table.scss";
import { StyledTableCell } from "../table";

interface DragAndDropTableProps {
  headers: string[];
  children: React.ReactNode;
}

export default function DragAndDropTable({ headers, children }: DragAndDropTableProps) {
  return (
    <TableContainer className='table-container'>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((header) => (
                <StyledTableCell align='center' key={header}>
                  {header}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        {children}
      </Table>
    </TableContainer>
  );
}
