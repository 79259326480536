import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import CalculateOutlined from "@mui/icons-material/CalculateOutlined";
import FoodBankOutlined from "@mui/icons-material/FoodBankOutlined";
import TimelineOutlined from "@mui/icons-material/TimelineOutlined";
import PersonAddAlt1Outlined from "@mui/icons-material/PersonAddAlt1Outlined";
import { AccessTimeOutlined, InventoryOutlined } from "@mui/icons-material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

export const routes = [
  {
    to: "/system-setup",
    icon: SettingsOutlined,
    labels: [{ label: "System Setup", to: "/system-setup" }],
  },
  {
    to: "/system-setup/welcome",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Welcome", to: "/system-setup/welcome" },
    ],
  },
  {
    to: "/system-setup/feed-types",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Feed Types", to: "/system-setup/feed-types" },
    ],
  },
  {
    to: "/system-setup/feed-plans",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Feed Plans", to: "/system-setup/feed-plans" },
    ],
  },
  {
    to: "/system-setup/feed-plans/:id",
    relatedTo: "/system-setup/feed-plans-sequence",
    icon: SettingsOutlined,
    labels: [
      { label: "Feed Plans", to: "/system-setup/feed-plans" },
      {
        label: "Feed Plan Sequence",
        to: "/system-setup/feed-plans-sequence/:id",
      },
    ],
  },
  {
    to: "/system-setup/animals",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Animals", to: "/system-setup/animals" },
    ],
  },
  {
    to: "/system-setup/feed-groups",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Feed Groups", to: "/system-setup/feed-groups" },
    ],
  },
  {
    to: "/system-setup/bins",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Bins", to: "/system-setup/bins" },
    ],
  },
  {
    to: "/system-setup/sites",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Sites", to: "/system-setup/sites" },
    ],
  },
  {
    to: "/system-setup/barns",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Barns", to: "/system-setup/barns" },
    ],
  },
  {
    to: "/system-setup/growth-curves",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Growth Curves", to: "/system-setup/growth-curves" },
    ],
  },
  {
    to: "/system-setup/growth-curves/:id",
    relatedTo: "/system-setup/growth-curves-sequence",
    icon: SettingsOutlined,
    labels: [
      { label: "Setup Growth Curves", to: "/system-setup/growth-curves" },
      {
        label: "Growth Curve Sequence",
        to: "/system-setup/growth-curves-sequence/:id",
      },
    ],
  },
  {
    to: "/system-setup/growth-adjustments",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Growth Adjustments", to: "/system-setup/growth-adjustments" },
    ],
  },
  {
    to: "/system-setup/setup-growth-adjustments-conditions/:id",
    relatedTo: "/system-setup/setup-growth-adjustments-conditions",
    icon: SettingsOutlined,
    labels: [
      { label: "Setup Growth Adjustments", to: "/system-setup/growth-adjustments" },
      {
        label: "Growth Adjustment Conditions",
        to: "/system-setup/setup-growth-adjustments-conditions/:id",
      },
    ],
  },
  {
    to: "/system-setup/site-combinations",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Site Combinations", to: "/system-setup/site-combinations" },
    ],
  },
  {
    to: "/system-setup/mills",
    icon: SettingsOutlined,
    labels: [
      { label: "System Setup", to: "/system-setup" },
      { label: "Setup Mills", to: "/system-setup/mills" },
    ],
  },
  {
    to: "/bin-management",
    icon: CalculateOutlined,
    labels: [{ label: "Bin Management", to: "/bin-management" }],
  },
  {
    to: "/feed-group-management",
    icon: FoodBankOutlined,
    labels: [{ label: "Feed Group Management", to: "/feed-group-management" }],
  },
  {
    to: "/feed-group-management/feed-plan-summary/:id",
    relatedTo: "/feed-group-management/feed-plan-summary",
    icon: SettingsOutlined,
    labels: [
      { label: "Feed Group Management", to: "/feed-group-management" },
      {
        label: "Feed Plan Summary",
        to: "/feed-group-management/feed-plan-summary/:id",
      },
    ],
  },
  {
    to: "/feed-orders",
    icon: TimelineOutlined,
    labels: [{ label: "Feed Orders", to: "/feed-orders" }],
  },
  {
    to: "/feed-orders/feed-load/:id",
    relatedTo: "/feed-orders/feed-load",
    icon: SettingsOutlined,
    labels: [
      { label: "Feed Orders", to: "/feed-orders" },
      {
        label: "Feed Load Details",
        to: "/feed-orders/feed-load/:id",
      },
    ],
  },
  {
    to: "/feed-forecast",
    icon: AccessTimeOutlined,
    labels: [{ label: "Feed Forecast", to: "/feed-forecast" }],
  },
  {
    to: "/feed-forecast/feed-group-detail",
    relatedTo: "/feed-forecast/feed-group-detail",
    icon: AccessTimeOutlined,
    labels: [
      { label: "Feed Forecast", to: "/feed-forecast" },
      { label: "Feed Group Detail", to: "/feed-forecast/feed-group-detail/:id" },
    ],
  },
  {
    to: "/user-admin",
    icon: PersonAddAlt1Outlined,
    labels: [{ label: "User Admin", to: "/user-admin" }],
  },
  {
    to: "/production-sequence",
    icon: ManageHistoryIcon,
    labels: [{ label: "Production Sequence", to: "/production-sequence" }],
  },
  {
    to: "/management-dashboard",
    icon: InventoryOutlined,
    labels: [{ label: "Management Dashboard", to: "/management-dashboard" }],
  },
];
