import "./home.scss";

import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import {
  AccessTimeOutlined,
  CalculateOutlined,
  FoodBank,
  InventoryOutlined,
  Timeline,
  Settings,
  PersonAddAlt,
} from "@mui/icons-material";

export function Home(): JSX.Element {
  useEffect(() => {
    document.getElementsByClassName("layout")[0]?.classList.add("with-image-bg");

    return () => document.getElementsByClassName("layout")[0]?.classList.remove("with-image-bg");
  }, []);

  return (
    <div className='homepage'>
      <ul className='homepage__links'>
        <li>
          <NavLink className='homepage__link' to='/system-setup'>
            <Settings className='homepage__icon' /> System Setup
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/bin-management'>
            <CalculateOutlined className='homepage__icon' />
            Bin Management
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/feed-group-management'>
            <FoodBank className='homepage__icon' />
            Feed Group Management
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/feed-forecast'>
            <AccessTimeOutlined className='homepage__icon' />
            Feed Forecast
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/feed-orders'>
            <Timeline className='homepage__icon' />
            Feed Orders
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/user-admin'>
            <PersonAddAlt className='homepage__icon' />
            User Admin
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/production-sequence'>
            <PersonAddAlt className='homepage__icon' />
            Production Sequence
          </NavLink>
        </li>
        <li>
          <NavLink className='homepage__link' to='/management-dashboard'>
            <InventoryOutlined className='homepage__icon' />
            Management Dashboard
          </NavLink>
        </li>
      </ul>

      <h1 className='homepage__title'>Welcome to feed plannr!</h1>
    </div>
  );
}
