import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { FeedTargetProps } from "../config-target-modal";
import "./feed-target.scss";

export interface ConfigFeedTargetProps {
  feedTarget: FeedTargetProps;
  onChangeFeedTarget: (feedTarget: FeedTargetProps) => void;
}

export function FeedTarget({ feedTarget, onChangeFeedTarget }: ConfigFeedTargetProps): JSX.Element {
  const handleChangeCheckbox = (): void => {
    feedTarget.override = !feedTarget.override;
    onChangeFeedTarget(feedTarget);
  };

  const handleChangeProductionTarget = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    feedTarget.dailyProductionTarget = Number(event.target.value);
    onChangeFeedTarget(feedTarget);
  };
  return (
    <section className='feed-target'>
      <div className='feed-target__day'>{feedTarget.dayOfWeek}</div>
      <div className='feed-target__override'>
        <FormControlLabel
          control={<Checkbox checked={feedTarget.override} onChange={handleChangeCheckbox} />}
          label='Override Production Target'
        />
      </div>
      <div className='feed-target__target'>
        <TextField
          label='Production Target (MT)'
          focused
          defaultValue={feedTarget.dailyProductionTarget}
          disabled={!feedTarget.override}
          type='number'
          onChange={(event) => handleChangeProductionTarget(event)}
        />
      </div>
    </section>
  );
}
