import "./import-feed-group-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAxios } from "../../../../hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackBar } from "../../../../providers";
import { useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../utils/download-file";

interface ImportFeedGroupInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  uploadFeedGroups: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImportFeedGroupInstruction = ({ open, setOpen, uploadFeedGroups }: ImportFeedGroupInstructionProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-groups/csv/template",
        data: null,
      });
      downloadFile(data, "feed-groups-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Feed Group Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>
            name, feed_plan, site, barn, count, start_date, bin, growth_curve, animal_type, animal_sub_type
          </span>
          <br />
          Where <span className='responsive-dialog__text-bold'>name</span> is the Feed Group Name,{" "}
          <span className='responsive-dialog__text-bold'>feed_plan</span> is the associated Feed Plan name,{" "}
          <span className='responsive-dialog__text-bold'>site</span> is the associated Site name,{" "}
          <span className='responsive-dialog__text-bold'>barn</span> is the associated Barn name,{" "}
          <span className='responsive-dialog__text-bold'>count</span> is the Starting Count number for the Feed Ledger,{" "}
          <span className='responsive-dialog__text-bold'>
            start_date is the Start Date when the Feed Group is active Format: DD/MM/YYYY, bin is a List of associated
            Bin names in the format: Bin 1:Bin 2:Bin 3, and growth_curve is the associated Growth Curve name,
          </span>
          <span className='responsive-dialog__text-bold'> animal_type</span> is the animal type,
          <span className='responsive-dialog__text-bold'> animal_sub_type</span> is the animal sub type,{" "}
          <span className='responsive-dialog__text-bold'> starting_weight</span> is the starting animal weight,{" "}
          <span className='responsive-dialog__text-bold'> starting_age</span> is the starting animal age,{" "}
          <span className='responsive-dialog__text-bold'> avg_weight_variance</span> is the average weight variance,{" "}
          <span className='responsive-dialog__text-bold'> mortality_rate</span> is the mortality rate,{" "}
          <span className='responsive-dialog__text-bold'> end_date</span> is the End Date when the Feed Group is ended
          (not less than 200 days after start date) Format: DD/MM/YYYY.
          <br />
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadFeedGroups} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportFeedGroupInstruction;
