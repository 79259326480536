import { DownloadOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Header } from "../../components/layout/header/header";
import { FeedGroupDetailsContent } from "./feed-group-detail-content/feed-group-detail-content";
import { useParams } from "react-router-dom";
import { downloadFile } from "../../utils/download-file";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";

export function FeedGroupDetailScreen(): JSX.Element {
  const { id } = useParams();

  const { post } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const exportAnimalForecastReport = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: `/client/feed-groups/${id}/animal-forecast/report`,
        data: null,
      });
      downloadFile(data, "animal-forecast-report.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  return (
    <div className='feed-group-details'>
      <Header>
        <Tooltip title='Export Data'>
          <IconButton
            onClick={() => {
              exportAnimalForecastReport();
            }}
          >
            <DownloadOutlined fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <FeedGroupDetailsContent />
    </div>
  );
}
