import { useEffect, useState } from "react";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import "./date-picker.scss";
import { Dayjs } from "dayjs";

const adapter = new AdapterDayjs();

interface DatePickerProps {
  dateTime: Date | null | undefined | string;
  name: string;
  disableFuture: boolean;
  disablePast: boolean;
  minDate?: Date;
  onChangeDate: (dateTime: Date | null) => void;
  className?: string;
}

export function DateTimePicker({
  dateTime,
  name,
  disableFuture,
  disablePast,
  minDate,
  onChangeDate,
  className,
}: DatePickerProps): JSX.Element {
  const [date, setDate] = useState(dateTime);

  useEffect(() => {
    setDate(dateTime ? new Date(dateTime) : null);
  }, [dateTime]);

  const onChange = (dateTime: Date | null): void => {
    setDate(dateTime);
    onChangeDate(dateTime);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMinMaxAdapterDate = (date: Date | undefined): Dayjs | undefined | any => {
    return date ? adapter.date(new Date(date)) : undefined;
  };

  return (
    <div className={`date-picker ${className}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={name}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={getMinMaxAdapterDate(minDate)}
          value={date ? new Date(date) : new Date()}
          onChange={(value: Date | null) => onChange(value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}
