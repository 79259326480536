import "./import-feed-plan-sequence-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks";
import { FeedPlanSequenceType } from "../../../../../interfaces";
import { useSnackBar } from "../../../../../providers";
import { getFileFormData } from "../../../../../utils/append-file";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../../utils/download-file";

interface ImportFeedPlanSequenceInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  importFeedPlanSequence: (feedPlanSequence: FeedPlanSequenceType[]) => void;
}

const ImportFeedPlanSequenceInstruction = ({
  open,
  setOpen,
  importFeedPlanSequence,
}: ImportFeedPlanSequenceInstructionProps) => {
  const theme = useTheme();
  const { id } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const uploadFeedPlanSequence = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const postData = async (): Promise<void> => {
        const data = await post<FeedPlanSequenceType[]>({
          url: `/client/feed-plans/${id}/items/csv`,
          data: formData,
        });
        importFeedPlanSequence(data);

        openSnackBarMessage("Successfully imported!", "success");
        setOpen(false);
      };

      postData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setOpen(false);
      });
    }
  };

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: `/client/feed-plans/${id}/items/csv/template`,
        data: null,
      });
      downloadFile(data, "feed-plan-sequence-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Feed Plan Sequence Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>sequence, feed_type, kg</span>
          <br />
          Where <span className='responsive-dialog__text-bold'>sequence</span> is the Sequence Number/Order for a given{" "}
          feed type, <span className='responsive-dialog__text-bold'>feed_type</span> is the Name of the Feed Type (You{" "}
          must have already created this Feed Type), and <span className='responsive-dialog__text-bold'>kg</span> is the{" "}
          Kgs Per Head Number.
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadFeedPlanSequence} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportFeedPlanSequenceInstruction;
