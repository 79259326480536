import { WeeklySnapshotTable } from "../../../../components/table/weekly-snapshot-table/weekly-snapshot-table";
import { WeeklySnapshot } from "../../../../interfaces";

interface FeedForecastWeeklySnapshotTableProps {
  headers: string[];
  rows: WeeklySnapshot[];
  fetchData: (message?: string) => void;
  loading: boolean;
}

export function FeedForecastWeeklySnapshotTable({
  headers,
  rows,
  fetchData,
  loading,
}: FeedForecastWeeklySnapshotTableProps): JSX.Element {
  return <WeeklySnapshotTable headers={headers} rows={rows} fetchData={fetchData} loading={loading} />;
}
