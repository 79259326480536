import PropTypes from "prop-types";
import fscreen from "fscreen";
import { useMemo, useRef, useState, useEffect, useCallback, createContext, useContext, RefObject } from "react";
import { WithChildren } from "../interfaces";

interface FullScreenContextInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fullscreenRef: RefObject<HTMLElement | any>;
  fullscreenEnabled: boolean;
  fullscreenActive: boolean;
  enterFullscreen: () => Promise<void>;
  exitFullscreen: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const FullscreenContext = createContext<FullScreenContextInterface>(null!);

export function useFullscreen() {
  return useContext(FullscreenContext);
}

export const FullscreenProvider = ({ children }: WithChildren) => {
  const fullscreenRef = useRef();
  const [active, setActive] = useState(false);
  useEffect(() => {
    const handleChange = () => {
      setActive(document.fullscreenElement === fullscreenRef.current);
    };
    fscreen.addEventListener("fullscreenchange", handleChange);
    return () => fscreen.removeEventListener("fullscreenchange", handleChange);
  }, []);
  const enterFullscreen = useCallback(async () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);
  const exitFullscreen = useCallback(async () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);
  const context = useMemo(() => {
    return {
      fullscreenRef,
      fullscreenEnabled: fscreen.fullscreenEnabled,
      fullscreenActive: active,
      enterFullscreen,
      exitFullscreen,
    };
  }, [active, enterFullscreen, exitFullscreen]);
  return <FullscreenContext.Provider value={context}>{children}</FullscreenContext.Provider>;
};

FullscreenProvider.propTypes = {
  children: PropTypes.node,
};
