import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./growth-adjustment-conditions-table.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GrowthAdjustmentCondition } from "../../../../../interfaces";
import { EditableTableCell } from "../../../../../components/table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../../../../../components/table/table";
import { useAxios } from "../../../../../hooks";
import { useSnackBar } from "../../../../../providers";
import { useNavigate, useParams } from "react-router-dom";
import { SelectChangeEvent, FormControl, Select, MenuItem } from "@mui/material";

interface GrowthAdjustmentConditionsTableProps {
  headers: string[];
  growthAdjustmentConditions: GrowthAdjustmentCondition[];
  loading: boolean;
  setGrowthAdjustmentConditions: Dispatch<SetStateAction<GrowthAdjustmentCondition[]>>;
  onAddChanged: (row: GrowthAdjustmentCondition, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function GrowthAdjustmentConditionsTable({
  headers,
  growthAdjustmentConditions,
  loading,
  setGrowthAdjustmentConditions,
  onAddChanged,
  isUnsavedChanges,
}: GrowthAdjustmentConditionsTableProps): JSX.Element {
  const [conditionTypes, setConditionTypes] = useState<string[] | []>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleConditionTypes();
  }, []);

  const handleConditionTypes = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<string[]>({ url: `/client/growth-adjustment/${id}/conditions/types` });

      setConditionTypes(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: GrowthAdjustmentCondition): void => {
    const data = [...growthAdjustmentConditions];
    data.map((item) => {
      if (item.id === row.id) {
        item = row;
        onAddChanged(row, false, true);
      }
      return row;
    });
    setGrowthAdjustmentConditions(data);
  };

  const removeGrowthAdjustmentCondition = (row: GrowthAdjustmentCondition): void => {
    let data = [...growthAdjustmentConditions];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((item) => item.id !== copyRow?.id);
      setGrowthAdjustmentConditions(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToGrowthAdjustments = (): void => {
    navigate("/system-setup/growth-adjustments");
  };

  const onChange = (e: SelectChangeEvent<string>, row: GrowthAdjustmentCondition): void => {
    const data = [...growthAdjustmentConditions];
    data.forEach((condition) => {
      if (condition.id === row.id) {
        condition.type = e.target.value as "DEFAULT_TYPE";
        onSaveChanges(condition);
      }
    });
  };
  return (
    <section className='growth-adjustment-conditions-table'>
      <div className='growth-adjustment-conditions-table__header' onClick={navigateToGrowthAdjustments}>
        <ArrowBackIcon fontSize='medium' className='growth-adjustment-conditions-table__back-icon' />
        <p className='growth-adjustment-conditions-table__title'>Edit Adjustment Reason</p>
      </div>
      <CustomizedTable
        headers={headers}
        isEmpty={!growthAdjustmentConditions?.length}
        loading={loading}
        isUnsavedChanges={!isUnsavedChanges}
      >
        {growthAdjustmentConditions.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <StyledTableCell align='center'>
              <FormControl variant='standard'>
                <Select value={row.type} label='Condition Type' onChange={(e) => onChange(e, row)}>
                  {conditionTypes?.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledTableCell>
            <EditableTableCell
              row={row}
              data={row.configuration}
              property='configuration'
              name='Condition Configuration'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button
                variant='outlined'
                startIcon={<CloseOutlined />}
                onClick={() => removeGrowthAdjustmentCondition(row)}
              >
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
