import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { SetupGrowthAdjustmentsTable } from "./setup-growth-adjustments-table/setup-growth-adjustments-table";
import { ChangeEvent, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { GrowthAdjustment } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import ImportGrowthAdjustmentInstruction from "./import-growth-adjustments-instructions-modal/import-growth-adjustments-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";

export function SetupGrowthAdjustments(): JSX.Element {
  const headers = [
    "Adjustment Reason",
    "Adjustment Factor (%)",
    "Conditions",
    "Start Date",
    "End Date",
    "Growth Adjustment Conditions",
    "Remove",
  ];

  const [growthAdjustments, setGrowthAdjustments] = useState<GrowthAdjustment[]>([]);
  const [changedRows, setChangedRows] = useState<GrowthAdjustment[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importGrowthAdjustmentsOpen, setImportGrowthAdjustmentsOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [growthAdjustmentsFile, setGrowthAdjustmentsFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchGrowthAdjustments();
  }, []);

  const refresh = (): void => {
    fetchGrowthAdjustments("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchGrowthAdjustments("Successfully cancelled!");
  };

  const fetchGrowthAdjustments = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthAdjustment[]>({ url: "/client/growth-adjustments" });

      setGrowthAdjustments(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateGrowthAdjustments = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setGrowthAdjustmentsFile(formData);
        await post({
          url: "/client/growth-adjustments/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadGrowthAdjustments(formData);
          setImportGrowthAdjustmentsOpen(false);
        })
        .catch((error) => {
          setImportGrowthAdjustmentsOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadGrowthAdjustments = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<GrowthAdjustment[]>({
        url: "/client/growth-adjustments/csv",
        data: formData || growthAdjustmentsFile,
      });
      importGrowthAdjustments(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: GrowthAdjustment, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as GrowthAdjustment[]);
  };

  const add = (): void => {
    const data = [...growthAdjustments];
    const growthAdjustmentData = [...changedRows];

    const growthAdjustment: GrowthAdjustment = {
      id: generatedId(36),
      subId: "",
      adjustmentsReason: data[0]?.adjustmentsReason,
      adjustmentsFactor: 0,
      conditions: data[0]?.conditions,
      startDate: new Date(),
      endDate: new Date(),
      isAdded: true,
      toDelete: false,
    };

    growthAdjustment.subId = growthAdjustment.id;
    data.push(growthAdjustment);
    growthAdjustmentData.push(growthAdjustment);

    setIsReadyForSave(false);
    setGrowthAdjustments(data);
    setChangedRows(growthAdjustmentData);
  };

  const importGrowthAdjustments = (importedGrowthAdjustments: GrowthAdjustment[]): void => {
    let data = [...growthAdjustments];
    let growthAdjustmentData = [...changedRows];
    importedGrowthAdjustments = importedGrowthAdjustments.map((growthAdjustment) => {
      growthAdjustment.id = generatedId(36);
      growthAdjustment.subId = growthAdjustment.id;
      growthAdjustment.isAdded = true;
      growthAdjustment.toDelete = false;
      return growthAdjustment;
    });

    data = data.concat(importedGrowthAdjustments);
    growthAdjustmentData = growthAdjustmentData.concat(importedGrowthAdjustments);

    setIsReadyForSave(false);
    setGrowthAdjustments(data);
    setChangedRows(growthAdjustmentData);
  };

  const getFinalSetupGrowthAdjustments = (): void => {
    let data = [...changedRows];

    data = data.map((growthAdjustment) => {
      if (growthAdjustment.isAdded) {
        growthAdjustment.id = null;
      }

      return growthAdjustment;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupGrowthAdjustments();

    const postData = async (): Promise<void> => {
      await post<GrowthAdjustment[]>({
        url: "/client/growth-adjustments/update",
        data: { data: changedRows },
      });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchGrowthAdjustments("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((growthAdjustment) => {
      if (growthAdjustment.isAdded && !growthAdjustment?.id) {
        growthAdjustment.id = growthAdjustment.subId;
      }

      return growthAdjustment;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportGrowthAdjustmentsModal = (): void => {
    setImportGrowthAdjustmentsOpen(true);
  };

  const handleGrowthAdjustmentsSetupComplete = (): void => {
    handleSetupComplete("GROWTH_ADJUSTMENTS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='setup-growth-adjustments'>
      <Header>
        <Tooltip title='Import Setup Growth Adjustments'>
          <IconButton onClick={openImportGrowthAdjustmentsModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleGrowthAdjustmentsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupGrowthAdjustmentsTable
        headers={headers}
        rows={growthAdjustments}
        setGrowthAdjustments={setGrowthAdjustments}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Growth Adjustments'
        addButtonLabel='Add Setup Growth Adjustments'
        refreshButtonLabel='Refresh Setup Growth Adjustments'
        cancelButtonLabel='Discard changes'
      />

      <ImportGrowthAdjustmentInstruction
        open={importGrowthAdjustmentsOpen}
        setOpen={setImportGrowthAdjustmentsOpen}
        uploadGrowthAdjustments={validateGrowthAdjustments}
      />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadGrowthAdjustments()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
