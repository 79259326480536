import "./import-feed-plan-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAxios } from "../../../../hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackBar } from "../../../../providers";
import { useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../utils/download-file";

interface ImportFeedPlanInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  uploadFeedPlans: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImportFeedPlanInstruction = ({ open, setOpen, uploadFeedPlans }: ImportFeedPlanInstructionProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-plans/csv/template",
        data: null,
      });
      downloadFile(data, "feed-plans-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Feed Plan Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>name, description</span>
          <br />
          Where <span className='responsive-dialog__text-bold'>name</span> is the Feed Plan and Description is text.
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadFeedPlans} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportFeedPlanInstruction;
