import { SidebarMenuItem } from "../interfaces";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import CalculateOutlined from "@mui/icons-material/CalculateOutlined";
import FoodBankOutlined from "@mui/icons-material/FoodBankOutlined";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import TimelineOutlined from "@mui/icons-material/TimelineOutlined";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import PersonAddAlt1Outlined from "@mui/icons-material/PersonAddAlt1Outlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

export const menuItems: SidebarMenuItem[] = [
  {
    label: "Home",
    link: "/home",
    expandable: false,
    icon: HomeOutlinedIcon,
  },
  {
    label: "System Setup",
    link: "/system-setup",
    expandable: true,
    icon: SettingsOutlined,
    expandableItems: [
      {
        label: "Welcome",
        link: "/system-setup/welcome",
      },
      {
        label: "Setup Mills",
        link: "/system-setup/mills",
      },
      {
        label: "Setup Feed Types",
        link: "/system-setup/feed-types",
      },
      {
        label: "Setup Feed Plans",
        link: "/system-setup/feed-plans",
      },
      {
        label: "Setup Growth Curves",
        link: "/system-setup/growth-curves",
      },
      {
        label: "Setup Animals",
        link: "/system-setup/animals",
      },
      {
        label: "Setup Growth Adjustments",
        link: "/system-setup/growth-adjustments",
      },
      {
        label: "Setup Sites",
        link: "/system-setup/sites",
      },
      {
        label: "Setup Barns",
        link: "/system-setup/barns",
      },
      {
        label: "Setup Bins",
        link: "/system-setup/bins",
      },
      {
        label: "Setup Feed Groups",
        link: "/system-setup/feed-groups",
      },
      {
        label: "Setup Site Combinations",
        link: "/system-setup/site-combinations",
      },
    ],
  },
  {
    label: "Bin Management",
    link: "/bin-management",
    expandable: false,
    icon: CalculateOutlined,
  },
  {
    label: "Feed Group Management",
    link: "/feed-group-management",
    expandable: false,
    icon: FoodBankOutlined,
  },
  {
    label: "Feed Forecast",
    link: "/feed-forecast",
    expandable: false,
    icon: AccessTimeOutlined,
  },
  {
    label: "Feed Orders",
    link: "/feed-orders",
    expandable: false,
    icon: TimelineOutlined,
  },
  {
    label: "User Admin",
    link: "/user-admin",
    expandable: false,
    icon: PersonAddAlt1Outlined,
  },
  {
    label: "Production Sequence",
    link: "/production-sequence",
    expandable: false,
    icon: ManageHistoryIcon,
  },
  {
    label: "Management Dashboard",
    link: "/management-dashboard",
    expandable: false,
    icon: InventoryOutlined,
  },
];
