import "./header.scss";

import { IconButton, Tooltip } from "@mui/material";

import { BreadCrumb } from "../breadcrumbs/breadcrumb";
import { UserAccountMenu } from "./user-account-menu/user-account-menu";
import { WithChildren } from "../../../interfaces";
import { useState } from "react";
import { useFullscreen } from "../../../providers/full-screen-provider";
import { HelpOutline, Fullscreen } from "@mui/icons-material";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithChildren {}

export const Header = ({ children }: Props): JSX.Element => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const { enterFullscreen, exitFullscreen } = useFullscreen();

  const onToggleFullScreen = (): void => {
    setShowFullScreen(!showFullScreen);
    showFullScreen ? exitFullscreen() : enterFullscreen();
  };

  return (
    <div className='header'>
      <BreadCrumb />
      <div className='header__actions'>
        {children}

        <Tooltip title='Help Docs'>
          <IconButton className='header__button'>
            <a className='header__link' href='https://mode40.com/' target='_blank' rel='noreferrer'>
              <HelpOutline fontSize='medium' htmlColor='#737373' />
            </a>
          </IconButton>
        </Tooltip>

        <Tooltip title='FullScreen'>
          <IconButton onClick={onToggleFullScreen}>
            <Fullscreen fontSize='medium' htmlColor='#737373' />
          </IconButton>
        </Tooltip>

        <UserAccountMenu />
      </div>
    </div>
  );
};
