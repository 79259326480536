import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { useAxios } from "../../../hooks";
import { FeedGroup } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { FeedPlanSummaryTable } from "./feed-plan-summary-table/feed-plan-summary-table";

export interface FeedPlanSummaryProps {
  sequence: number;
  feedType: string;
  kgsPerHead: number;
  plannedAmount: number;
  actualAmount: number;
}

export function FeedPlanSummary(): JSX.Element {
  const headers = ["Sequence", "Feed Type name", "Kgs per Head", "Planned Amount (MT)", "Actual Amount (MT)"];

  const { id } = useParams();
  const [summary, setSummary] = useState<FeedPlanSummaryProps[]>([]);
  const [feedGroup, setFeedGroup] = useState<FeedGroup>();
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    fetchFeedGroup();
    fetchFeedPlanSummary();
  }, []);

  const refresh = (): void => {
    fetchFeedGroup();
    fetchFeedPlanSummary("Successfully refreshed!");
  };

  const fetchFeedGroup = (): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroup>({
        url: `/client/feed-groups/${id}`,
      });

      setFeedGroup(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchFeedPlanSummary = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedPlanSummaryProps[]>({
        url: `/client/feed-groups/${id}/plan`,
      });

      setSummary(data);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  return (
    <section className='bin-management'>
      <Header />

      <FeedPlanSummaryTable headers={headers} rows={summary} feedGroup={feedGroup} loading={loading} />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh Feed Plan Summary' />
    </section>
  );
}
