import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./growth-curves-sequence-table.scss";
import { Dispatch, SetStateAction } from "react";
import { GrowthCurveSequence } from "../../../../../interfaces";
import { EditableTableCell } from "../../../../../components/table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../../../../../components/table/table";
import { useNavigate } from "react-router-dom";

interface GrowthCurveSequenceTableProps {
  headers: string[];
  growthCurvesSequence: GrowthCurveSequence[];
  loading: boolean;
  growthCurveName: string;
  setGrowthCurvesSequence: Dispatch<SetStateAction<GrowthCurveSequence[]>>;
  onAddChanged: (row: GrowthCurveSequence, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function GrowthCurveSequenceTable({
  headers,
  growthCurvesSequence,
  loading,
  growthCurveName,
  setGrowthCurvesSequence,
  onAddChanged,
  isUnsavedChanges,
}: GrowthCurveSequenceTableProps): JSX.Element {
  const navigate = useNavigate();

  const onSaveChanges = (row: GrowthCurveSequence): void => {
    const data = [...growthCurvesSequence];
    data.map((item) => {
      if (item.id === row.id) {
        item = row;
        onAddChanged(row, false, true);
      }
      return row;
    });
    setGrowthCurvesSequence(data);
  };

  const removeGrowthCurveSequence = (row: GrowthCurveSequence): void => {
    let data = [...growthCurvesSequence];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((item) => item.id !== copyRow?.id);
      setGrowthCurvesSequence(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToGrowthCurves = (): void => {
    navigate("/system-setup/growth-curves");
  };
  return (
    <section className='growth-curve-sequence-table'>
      <div className='growth-curve-sequence-table__header' onClick={navigateToGrowthCurves}>
        <ArrowBackIcon fontSize='medium' className='growth-curve-sequence-table__back-icon' />
        <p className='growth-curve-sequence-table__title'>Edit {growthCurveName}</p>
      </div>
      <CustomizedTable
        headers={headers}
        isEmpty={!growthCurvesSequence?.length}
        loading={loading}
        isUnsavedChanges={!isUnsavedChanges}
      >
        {growthCurvesSequence.map((row) => (
          <StyledTableRow key={row.day}>
            <EditableTableCell
              row={row}
              data={row.day}
              property='day'
              name='Day'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.growthKgsPerHead}
              property='growthKgsPerHead'
              name='Growth Kg Per Head'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.consumptionKgsPerHead}
              property='consumptionKgsPerHead'
              name='Consumption Kg Per Head'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button variant='outlined' startIcon={<CloseOutlined />} onClick={() => removeGrowthCurveSequence(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
