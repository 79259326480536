import "./site-combinations-table.scss";

import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Payload, Site, SiteCombination } from "../../../../interfaces";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";
import { EditableMultipleSelectCell } from "../../../../components/table/editable-multiple-select/editable-multiple-select";

interface SetupSiteCombinationsTableProps {
  headers: string[];
  rows: SiteCombination[] | [];
  loading: boolean;
  setSiteCombinations: Dispatch<SetStateAction<SiteCombination[]>>;
  onAddChanged: (row: SiteCombination, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function SetupSiteCombinationTable({
  headers,
  rows,
  loading,
  setSiteCombinations,
  onAddChanged,
  isUnsavedChanges,
}: SetupSiteCombinationsTableProps): JSX.Element {
  const [sites, setSites] = useState<Payload[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleSites();
  }, []);

  const handleSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Payload[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: SiteCombination): void => {
    const data = [...rows];
    data.map((siteCombination) => {
      if (siteCombination.id === row.id) {
        siteCombination = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setSiteCombinations(data);
  };

  const removeSiteCombination = (row: SiteCombination): void => {
    let data = [...rows];
    const copyRow = { ...row };

    if (row?.id) {
      data = data.filter((siteCombination) => siteCombination.id !== copyRow?.id);
      setSiteCombinations(data);
      onAddChanged(copyRow, true);
    }
  };

  const getSiteNames = (sites: Site[]): string[] => {
    return sites?.map((site) => site?.name);
  };
  return (
    <section className='setup-site-combinations-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows &&
          rows.map((row, index) => (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableTableCell
                row={row}
                data={row.name}
                property='name'
                name='Name'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableMultipleSelectCell
                row={row}
                name='Sites'
                data={getSiteNames(row.sites)}
                property='siteIds'
                valueProperty='sites'
                menuItems={sites}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='error'
                  startIcon={<CloseOutlined />}
                  onClick={() => removeSiteCombination(row)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </CustomizedTable>
    </section>
  );
}
