import { useEffect, useState } from "react";
import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { cardManagement } from "../../config/card-management";
import { useAxios } from "../../hooks";
import { LogDescription, WeeklySnapshot, WeeklySnapshotData } from "../../interfaces";
import { useSnackBar } from "../../providers";
import { generateWeeklySnapshotTable } from "../../utils/weekly-snapshot-table";
import { ManagementDashboardContent } from "./management-dashboard-content/management-dashboard-content";

export interface ManagementDashboard {
  daysUntilOutage: number;
  daysOfFeed: number;
  stageFeedingAccuracy: number;
  shippingDatePVA: number;
  targetWeightPVA: number;
  averageLoadSize: number;
  singleSiteLoads: number;
  fullLoads: number;
  singleBarnLoads: number;
  forecastLength: number;
  plannedOrder: number;
  orderAccuracy: number;
  weeklySnapshot: WeeklySnapshotData;
}

export function ManagementDashboard(): JSX.Element {
  const [cardManagementData, setCardManagementData] = useState(cardManagement);
  const [weeklySnapshotHeaders, setWeeklySnapshotHeaders] = useState<string[]>([]);
  const [weeklySnapshot, setWeeklySnapshot] = useState<WeeklySnapshot[]>([]);
  const [logs, setLogs] = useState<LogDescription[]>([]);
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    searchLogs();
    fetchManagementDashboard();
  }, []);

  const fetchManagementDashboard = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const response = await get<ManagementDashboard>({
        url: "/client/companies/dashboard",
      });
      handleDashboardContentCards(response);
      const { headers, weeklySnapshotData } = generateWeeklySnapshotTable(response.weeklySnapshot);
      setWeeklySnapshot(weeklySnapshotData);
      setWeeklySnapshotHeaders(headers);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const searchLogs = (): void => {
    const fetchData = async (): Promise<void> => {
      const response = await post<LogDescription[]>({
        url: "/client/logs/search",
      });
      let capacityCount = 0;
      let outageCount = 0;
      let partialLoadCount = 0;
      let overfillCount = 0;
      response.forEach((log) => {
        switch (log?.issueType) {
          case "CAPACITY":
            capacityCount++;
            break;
          case "OUTAGE":
            outageCount++;
            break;
          case "PARTIAL_LOAD":
            partialLoadCount++;
            break;
          case "OVERFILL":
            overfillCount++;
            break;
          default:
            break;
        }
      });
      setLogs([
        {
          issueType: "Inadequate Capacity",
          count: capacityCount,
        },
        {
          issueType: "Projected Feed Outage",
          count: outageCount,
        },
        {
          issueType: "Partial Load",
          count: partialLoadCount,
        },
        {
          issueType: "Predicted Overfill",
          count: overfillCount,
        },
      ]);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleDashboardContentCards = (response: ManagementDashboard): void => {
    let data = [...cardManagementData];
    data = data.map((cardManagement) => {
      cardManagement.cards.map((card) => {
        card.items.map((item) => {
          switch (item.label) {
            case "Days until outage":
              item.value = response.daysUntilOutage;
              item.color = handleCardColor(item.value, 2, 1);
              break;
            case "Days of feed":
              item.value = response.daysOfFeed;
              item.color = handleCardColor(item.value, 2, 1);
              break;
            case "Stage Feeding Accuracy":
              item.value = response.stageFeedingAccuracy;
              item.color = handleCardColor(item.value, 97.1, 50);
              break;
            case "Shipping Date PVA":
              item.value = response.shippingDatePVA;
              item.color = handleCardColor(item.value, 98.1, 50);
              break;
            case "Target Weight PVA":
              item.value = response.targetWeightPVA;
              item.color = handleCardColor(item.value, 94.2, 50);
              break;
            case "Average Load Size":
              item.value = response.averageLoadSize;
              break;
            case "% Single Site Loads":
              item.value = response.singleSiteLoads;
              break;
            case "% Full Loads":
              item.value = response.fullLoads;
              break;
            case "% Single Barn Loads":
              item.value = response.singleBarnLoads;
              break;
            case "Forecast Length (days)":
              item.value = response.forecastLength;
              break;
            case "Planned Order %":
              item.value = response.plannedOrder;
              break;
            case "Order Accuracy %":
              item.value = response.orderAccuracy;
              break;
            default:
              break;
          }
        });
      });
      return cardManagement;
    });
    setCardManagementData(data);
  };

  const handleCardColor = (value: number, green: number, red: number): string => {
    if (value >= green) {
      return "success";
    }
    if (value < green && value >= red) {
      return "warning";
    }
    if (value < red) {
      return "danger";
    }
    return "";
  };

  const refresh = (): void => {
    fetchManagementDashboard("Successfully refreshed!");
  };

  return (
    <div className='management-dashboard'>
      <Header />

      <ManagementDashboardContent
        cardManagementData={cardManagementData}
        weeklySnapshotHeaders={weeklySnapshotHeaders}
        weeklySnapshot={weeklySnapshot}
        logs={logs}
        loading={loading}
      />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh Management Dashboard' />
    </div>
  );
}
