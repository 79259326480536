// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  auth0Domain: "dev-x42r9e3p.us.auth0.com",
  auth0ClientId: "Y1FgffAMTbAc36f6RohKHWhzw01MiXHA",
  auth0Audience: "https://dev-x42r9e3p.us.auth0.com/api/v2/",
  apiBaseUrl: "http://159.203.48.136/api",
  snackbarAnchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  snackBarAutoHideDuration: 3000,
};
