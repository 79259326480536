export const downloadFile = (data: BlobPart, fileName: string): void => {
  const parsedResponse = data;
  const blob = new Blob([parsedResponse], { type: "text/xlsx" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
