import "./setup-mills-table.scss";

import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { Dispatch, SetStateAction } from "react";

import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { FeedOrderAlgorithm, Mill, Payload } from "../../../../interfaces";
import { useSnackBar } from "../../../../providers";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";

interface SetupMillsTableProps {
  headers: string[];
  rows: Mill[] | [];
  feedOrderAlgorithm: FeedOrderAlgorithm[];
  loading: boolean;
  setMills: Dispatch<SetStateAction<Mill[]>>;
  onAddChanged: (row: Mill, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function SetupMillsTable({
  headers,
  rows,
  feedOrderAlgorithm,
  loading,
  setMills,
  onAddChanged,
  isUnsavedChanges,
}: SetupMillsTableProps): JSX.Element {
  const { openSnackBarMessage } = useSnackBar();

  const onSaveChanges = (row: Mill): void => {
    const data = [...rows];
    data.map((mill) => {
      if (mill.id === row.id) {
        mill = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setMills(data);
  };

  const removeMill = (row: Mill): void => {
    let data = [...rows];
    const copyRow = { ...row };

    if (!row?.canBeDeleted) {
      openSnackBarMessage("Mill: mill name is associated with sites", "error");
    }

    if (row?.id && row?.canBeDeleted) {
      data = data.filter((mill) => mill.id !== copyRow?.id);
      setMills(data);
      onAddChanged(copyRow, true);
    }
  };

  const handleFeedOrderAlgorithmNames = (): Payload[] => {
    return feedOrderAlgorithm.map((algorithm) => ({
      id: algorithm.algorithmStrategy,
      name: algorithm.algorithmName,
    }));
  };
  return (
    <section className='setup-mills-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows &&
          rows.map((row, index) => (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableTableCell
                row={row}
                data={row.name}
                property='name'
                name='Name'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.mondayTarget}
                property='mondayTarget'
                name='Monday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.tuesdayTarget}
                property='tuesdayTarget'
                name='Tuesday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.wednesdayTarget}
                property='wednesdayTarget'
                name='Wednesday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.thursdayTarget}
                property='thursdayTarget'
                name='Thursday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.fridayTarget}
                property='fridayTarget'
                name='Friday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.saturdayTarget}
                property='saturdayTarget'
                name='Saturday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableTableCell
                row={row}
                data={row.sundayTarget}
                property='sundayTarget'
                name='Sunday Target'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />

              <EditableSelectCell
                row={row}
                data={row.algorithmStrategy}
                menuItems={handleFeedOrderAlgorithmNames()}
                property='algorithmStrategy'
                name='Algorithm Strategy'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  disabled={!row.canBeDeleted}
                  color={"error"}
                  startIcon={<CloseOutlined />}
                  onClick={() => removeMill(row)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </CustomizedTable>
    </section>
  );
}
