import "./create-feed-load-modal.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import CustomizedTable, { StyledTableRow } from "../../../../components/table/table";
import { useAxios } from "../../../../hooks";
import { Bin } from "../../../../interfaces";
import { useSnackBar } from "../../../../providers";
interface FeedLoadModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchFeedLoad: (message?: string) => void;
  bins: Bin[] | undefined;
  id: string | undefined;
}

interface FeedLoadData {
  binId: string;
  amount: number;
}

const FeedLoadModal = ({ open, setOpen, fetchFeedLoad, bins, id }: FeedLoadModalProps) => {
  const theme = useTheme();
  const headers = ["Bin", "Amount"];
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState<FeedLoadData>({ binId: "", amount: 0 });

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  useEffect(() => {
    if (bins) {
      setData({ binId: bins[0]?.id, amount: 0 });
    }
  }, [id]);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleCreate = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedLoadData[]>({
        url: `/client/feed-orders/${id}/add`,
        data: data,
      });

      fetchFeedLoad("Successfully created!");
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onSaveChanges = (data: FeedLoadData): void => {
    setData(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Update Record
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers}>
          <StyledTableRow>
            <EditableSelectCell
              row={data}
              name='Bin'
              menuItems={bins}
              data={data.binId}
              property='binId'
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={data}
              name='Amount'
              data={data.amount}
              property='amount'
              onSaveChanges={onSaveChanges}
            />
          </StyledTableRow>
        </CustomizedTable>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleCreate} className='responsive-dialog__button-update'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FeedLoadModal;
