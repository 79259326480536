import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import "./validation-modal.scss";

interface ValidationModalProps {
  validationState: {
    error: string;
    modalState: boolean;
  };
  setValidationState: React.Dispatch<
    React.SetStateAction<{
      error: string;
      modalState: boolean;
    }>
  >;
  onAgree: () => void;
}

export default function ValidationModal({ validationState, setValidationState, onAgree }: ValidationModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const discardChanges = () => {
    setValidationState({
      error: "",
      modalState: false,
    });
  };

  return (
    <Dialog
      open={validationState.modalState}
      onClose={() => setValidationState({ ...validationState, modalState: false })}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Validation Error
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>{validationState.error}</DialogContentText>
      </DialogContent>

      <Button variant='outlined' component='label' className='responsive-dialog__import-button' onClick={onAgree}>
        Agree
      </Button>
      <Button
        variant='outlined'
        color='error'
        component='label'
        className='responsive-dialog__import-button'
        onClick={discardChanges}
      >
        Discard
      </Button>
    </Dialog>
  );
}
