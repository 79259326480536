import { Autocomplete, Checkbox, IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import "./feed-forecast-filter.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import ContentPasteGoOutlined from "@mui/icons-material/ContentPasteGoOutlined";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { Mill, Site } from "../../../../interfaces";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface FeedForecastFilter {
  options: Site[];
  mills: Mill[];
  turnFilter: boolean;
  onTurnFilter: () => void;
  selectedSites: Site[];
  selectedMills: Mill[];
  setSelectedSites: Dispatch<SetStateAction<Site[]>>;
  setSelectedMills: Dispatch<SetStateAction<Mill[]>>;
  generateFeedOrders: () => void;
  exportForecastData: () => void;
  exportFeedOrdersData: () => void;
  deleteFeedOrders: () => void;
  fetchFeedForecast: (message?: string, sites?: Site[], mills?: Mill[]) => void;
}

export function FeedForecastFilter({
  options,
  mills,
  turnFilter,
  onTurnFilter,
  selectedSites,
  selectedMills,
  setSelectedSites,
  setSelectedMills,
  generateFeedOrders,
  exportForecastData,
  exportFeedOrdersData,
  deleteFeedOrders,
  fetchFeedForecast,
}: FeedForecastFilter): JSX.Element {
  const [filterLabel, setFilterLabel] = useState("Turn Filter On");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onChangeFilter = (): void => {
    onTurnFilter();
    setFilterLabel(!turnFilter ? "Turn Filter Off" : "Turn Filter On");
    if (turnFilter) {
      setSelectedSites([]);
      setSelectedMills([]);
    }
  };

  const onChangeSelectSites = (event: SyntheticEvent<Element, Event>, values: Site[]): void => {
    setSelectedSites(values);
    setSelectedMills([]);
    if (turnFilter) {
      fetchFeedForecast("Successfully applied filter!", values, []);
    }
  };

  const onChangeSelectMills = (event: SyntheticEvent<Element, Event>, values: Mill[]): void => {
    setSelectedMills(values);
    setSelectedSites([]);
    if (turnFilter) {
      fetchFeedForecast("Successfully applied filter!", [], values);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportForecastData = (): void => {
    exportForecastData();
    handleClose();
  };

  const handleExportFeedOrdersData = (): void => {
    exportFeedOrdersData();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className='feed-forecast-filter'>
      <Autocomplete
        multiple
        id='filter-by-site'
        options={options}
        value={selectedSites}
        disableCloseOnSelect
        onChange={(event, values) => onChangeSelectSites(event, values)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label='Filter By Site' placeholder='Filter By Site' />}
      />
      <Autocomplete
        multiple
        id='filter-by-mill'
        sx={{ marginLeft: 2 }}
        options={mills}
        value={selectedMills}
        disableCloseOnSelect
        onChange={(event, values) => onChangeSelectMills(event, values)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label='Filter By Mill' placeholder='Filter By Mill' />}
      />
      {turnFilter ? (
        <Tooltip title={filterLabel}>
          <IconButton className='feed-forecast-filter__button' onClick={onChangeFilter}>
            <FilterListIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={filterLabel}>
          <IconButton className='feed-forecast-filter__button' onClick={onChangeFilter}>
            <FilterListOffIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title='Generate Feed Orders'>
        <IconButton className='feed-forecast-filter__button' onClick={generateFeedOrders}>
          <ContentPasteGoOutlined fontSize='medium' />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete Feed Orders'>
        <IconButton className='feed-forecast-filter__button' onClick={deleteFeedOrders}>
          <ContentPasteOffIcon fontSize='medium' />
        </IconButton>
      </Tooltip>
      <Tooltip title='Export Data'>
        <IconButton
          className='feed-forecast-filter__button'
          id='basic-button'
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup='true'
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <DownloadOutlined fontSize='medium' />
        </IconButton>
      </Tooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleExportForecastData}>Forecast</MenuItem>
        <MenuItem onClick={handleExportFeedOrdersData}>Feed Orders</MenuItem>
      </Menu>
    </div>
  );
}
