import Button from "@mui/material/Button";
import "./feed-load-table.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FeedLoad, Mill, Order } from "../../../../interfaces";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../../../../components/table/table";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { DateTimePicker } from "../../../../components/date-picker/date-picker";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface FeedLoadTableProps {
  headers: string[];
  rows: Order[];
  mills: Mill[];
  feedLoad: FeedLoad | undefined;
  isUnsavedChanges: boolean;
  onUpdateScheduledDateAndMill: (dateTime: Date | null | undefined | string, millId: string) => void;
  setFeedLoadOrders: Dispatch<SetStateAction<Order[]>>;
  onAddChanged: (row: Order, isDeleted?: boolean, isModified?: boolean) => void;
  loading: boolean;
}

export function FeedLoadTable({
  headers,
  rows,
  mills,
  feedLoad,
  onUpdateScheduledDateAndMill,
  setFeedLoadOrders,
  onAddChanged,
  loading,
  isUnsavedChanges,
}: FeedLoadTableProps): JSX.Element {
  const [activeMillId, setActiveMillId] = useState<string | undefined>(feedLoad?.millId || "");
  const [activeDate, setActiveDate] = useState<Date | string | undefined | null>(feedLoad?.scheduledDate);

  useEffect(() => {
    setActiveMillId(feedLoad?.millId || "");
    setActiveDate(feedLoad?.scheduledDate);
  }, [feedLoad]);

  const onSaveChanges = (row: Order): void => {
    const data = [...rows];
    data?.map((order) => {
      if (order.id === row.id) {
        order = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setFeedLoadOrders(data);
  };

  const removeFeedLoadOrder = (row: Order): void => {
    let data = [...rows];
    const copyRow = { ...row };

    if (row?.id) {
      data = data?.filter((order) => order?.id !== copyRow?.id);
      setFeedLoadOrders(data);
      onAddChanged(copyRow, true);
    }
  };

  const onChangeScheduledDate = (dateTime: Date | null): void => {
    if (activeMillId) {
      setActiveDate(dateTime);
      onUpdateScheduledDateAndMill(dateTime, activeMillId);
    }
  };

  const onChangeMill = (e: SelectChangeEvent<string | null>): void => {
    if (e.target.value) {
      setActiveMillId(activeMillId);
      onUpdateScheduledDateAndMill(activeDate, e.target.value);
    }
  };
  return (
    <section className='feed-load-table'>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DateTimePicker
            dateTime={activeDate}
            disableFuture={false}
            disablePast={true}
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            name='Scheduled Date'
            onChangeDate={onChangeScheduledDate}
            className={"feed-load-table__date-picker"}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='mill-label'>Mill</InputLabel>
            <Select labelId='mill-label' id='mills-select' value={activeMillId} label='Mill' onChange={onChangeMill}>
              {mills?.map(
                (mill) =>
                  mill?.id && (
                    <MenuItem value={mill.id} key={mill.id}>
                      {mill?.name}
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows?.map((row, index) => (
          <StyledTableRow key={row.id + "_" + index}>
            <StyledTableCell align='center'>{row?.feedGroupName}</StyledTableCell>
            <StyledTableCell align='center'>{row?.feedTypeName}</StyledTableCell>
            <StyledTableCell align='center'>{row?.site?.name}</StyledTableCell>
            <StyledTableCell align='center'>{row?.barn?.name}</StyledTableCell>
            <EditableSelectCell
              row={row}
              data={row?.binId}
              property='binId'
              menuItems={row?.barn?.bins}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
              name='Bin'
            />
            <EditableTableCell
              row={row}
              data={row?.amount}
              property='amount'
              name='Amount'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>{feedLoad?.maxAmount}</StyledTableCell>
            <StyledTableCell align='center'>
              <Button variant='outlined' onClick={() => removeFeedLoadOrder(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
