import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTableCell } from "../../../../components/table/table";
import { useDoubleClick } from "../../../../hooks";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { User } from "../../../../interfaces";
import "./user-admin-table-cell.scss";

interface EditableTableAdminCellProps {
  row: User;
  data: boolean;
  onSaveChanges: (row: User) => void;
}

export function EditableTableAdminCell({ row, data, onSaveChanges }: EditableTableAdminCellProps): JSX.Element {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(data);
  const menuItems = [
    {
      name: "Admin",
      value: true,
    },
    {
      name: "User",
      value: false,
    },
  ];

  useEffect(() => {
    setValue(data);
  }, [data]);

  const hybridClick = useDoubleClick(
    () => handleDoubleRowClick(),
    () => handleRowClick(),
  );

  const handleDoubleRowClick = (): void => {
    setIsEditable(!isEditable);
  };

  const handleRowClick = (): void => {
    console.log("click");
  };

  const onChange = (e: SelectChangeEvent<string>): void => {
    if (onSaveChanges) {
      if (e.target.value === "Admin") {
        row.isAdmin = true;
      } else {
        row.isAdmin = false;
      }
      setValue(row.isAdmin);
      setIsEditable(!isEditable);
      onSaveChanges(row);
    }
  };
  return (
    <StyledTableCell align='center' className='table-cell' onClick={hybridClick}>
      {isEditable ? (
        <FormControl variant='standard'>
          <Select value={value ? "Admin" : "User"} onChange={(e) => onChange(e)}>
            {menuItems &&
              menuItems.map((item) => (
                <MenuItem value={item.name} key={item.name}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : value ? (
        <CheckCircleOutlineIcon fontSize='large' className='table-cell__done-icon' />
      ) : (
        <BlockIcon fontSize='large' className='table-cell__block-icon' />
      )}
    </StyledTableCell>
  );
}
