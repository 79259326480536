import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import "./feed-plan-summary-table.scss";
import { FeedGroup } from "../../../../interfaces";
import { FeedPlanSummaryProps } from "../feed-plan-summary";
import { Grid } from "@mui/material";

interface FeedPlanSummaryTableProps {
  headers: string[];
  rows: FeedPlanSummaryProps[];
  feedGroup: FeedGroup | undefined;
  loading: boolean;
}

export function FeedPlanSummaryTable({ headers, rows, feedGroup, loading }: FeedPlanSummaryTableProps): JSX.Element {
  const feedGroupSummaryHeader = [
    {
      label: "Feed group name",
      value: feedGroup?.name,
    },
    {
      label: "Start Date",
      value: feedGroup?.startDate,
    },
    {
      label: "# of animals",
      value: feedGroup?.count,
    },
    {
      label: "Feed Plan Name",
      value: feedGroup?.feedPlan?.name,
    },
  ];
  return (
    <section className='feed-plan-summary-table'>
      <div className='feed-plan-summary-table__header'>
        {feedGroupSummaryHeader?.map((header) => (
          <Grid container spacing={2} key={header.label}>
            <Grid item xs={6}>
              <p className='feed-plan-summary-table__label'>{header?.label}:</p>
            </Grid>
            <Grid item xs={6}>
              <p className='feed-plan-summary-table__value'>
                {(typeof header?.value === "string" || typeof header?.value === "number") && header?.value}
              </p>
            </Grid>
          </Grid>
        ))}
      </div>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading}>
        {rows.map((row) => (
          <StyledTableRow key={row.sequence}>
            <StyledTableCell align='center' component='th' scope='row'>
              {row.sequence}
            </StyledTableCell>
            <StyledTableCell align='center'>{row.feedType}</StyledTableCell>
            <StyledTableCell align='center'>{row.kgsPerHead}</StyledTableCell>
            <StyledTableCell align='center'>{row.plannedAmount}</StyledTableCell>
            <StyledTableCell align='center'>{row.actualAmount}</StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
