import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import "./table.scss";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface CustomizedTableProps {
  headers: string[];
  isEmpty?: boolean;
  loading?: boolean;
  children: React.ReactNode;
  isUnsavedChanges?: boolean;
}

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Kumbh Sans",
    backgroundColor: "#F8F8F8",
    color: "#4A8D6A",
    borderBottom: "1px solid #4A8D6A",
    fontSize: 22,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Kumbh Sans",
    fontSize: 18,
    backgroundColor: "#F8F8F8",
    borderBottom: "1px solid #F8F8F8",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTable({
  headers,
  children,
  isEmpty,
  loading,
  isUnsavedChanges,
}: CustomizedTableProps) {
  return (
    <div className='table-wrapper'>
      <TableContainer className='table-container'>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers &&
                headers.map((header) => (
                  <StyledTableCell align='center' key={header}>
                    {header}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow className='table-container__row'>
                <StyledTableCell>
                  <CircularProgress color='success' className='table-container__spinner' />
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty ? (
              <div className='table-container__empty-rows'>
                <Typography variant='h4' component='h5' m={2}>
                  Empty Rows
                </Typography>
                <HourglassEmptyIcon className='table-container__empty-icon' />
              </div>
            ) : (
              children
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isUnsavedChanges && <p className='table-wrapper__warning'>Warning: There are unsaved changes on this page</p>}
    </div>
  );
}
