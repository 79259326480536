import "./toggle-button.scss";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import cn from "classnames";

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

export const ToggleButton = ({ collapsed, onClick }: Props): JSX.Element => {
  const classNames = cn("toggle-button", { "toggle-button--collapsed": collapsed });

  return (
    <div className={classNames} onClick={onClick}>
      <DoubleArrowIcon className='toggle-button__icon' />
    </div>
  );
};
