import "./expanded-menu.scss";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";

interface ExpandedMenuProps {
  expanded: boolean;
  expandableItems: { label: string; link: string }[] | undefined;
}

export const ExpandedMenu = ({ expanded, expandableItems }: ExpandedMenuProps): JSX.Element => {
  return (
    <div className='expanded-menu'>
      <Collapse in={expanded} timeout='auto' unmountOnExit className='expanded-menu__items'>
        {expandableItems &&
          expandableItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.link}
              className={({ isActive }) => `expanded-menu__link ${isActive && "expanded-menu__link--active"}`}
            >
              {item.label}
            </NavLink>
          ))}
      </Collapse>
    </div>
  );
};
