import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import "./feed-group-management-table.scss";
import { Barn, Bin, FeedGroupManagementType, Site } from "../../../interfaces";
import { useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { EditableMultipleSelectCell } from "../../../components/table/editable-multiple-select/editable-multiple-select";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import AnimalMovementModal from "../../../components/animal-movement-dialog/animal-movement-dialog";
import { useNavigate } from "react-router-dom";
import { EditableDatePickerCell } from "../../../components/table/editable-datepicker-cell/editable-datepicker-cell";

interface FeedGroupManagementTableProps {
  headers: string[];
  rows: FeedGroupManagementType[];
  loading: boolean;
  fetchFeedGroups: () => void;
  setFeedGroups: React.Dispatch<React.SetStateAction<FeedGroupManagementType[]>>;
  onAddChanges: (row: FeedGroupManagementType, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function FeedGroupManagementTable({
  headers,
  rows,
  loading,
  fetchFeedGroups,
  setFeedGroups,
  onAddChanges,
  isUnsavedChanges,
}: FeedGroupManagementTableProps): JSX.Element {
  const [sites, setSites] = useState<Site[] | []>([]);
  const [barns, setBarns] = useState<Barn[] | []>([]);
  const [bins, setBins] = useState<Bin[] | []>([]);
  const [animalMovementOpen, setAnimalMovementOpen] = useState(false);
  const [animalCount, setAnimalCount] = useState(0);
  const [feedGroupId, setFeedGroupId] = useState("");

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedGroupSites();
    handleFeedGroupBarns();
    handleFeedGroupBins();
  }, []);

  const handleFeedGroupSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedGroupBarns = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Barn[]>({ url: "/client/barns" });

      setBarns(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedGroupBins = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Bin[]>({ url: "/client/bins" });

      setBins(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleOpenModal = (count: number, id: string | null): void => {
    setAnimalCount(count);
    if (id) {
      setFeedGroupId(id);
    }
    setAnimalMovementOpen(true);
  };

  const navigateToFeedPlanSummary = (id: string | null): void => {
    navigate(`/feed-group-management/feed-plan-summary/${id}`);
  };

  const onSaveChanges = (row: FeedGroupManagementType): void => {
    const data = [...rows];
    data.map((feedGroup) => {
      if (feedGroup.id === row.id) {
        feedGroup = { ...row };
        onAddChanges(feedGroup, false, true);
      }
      return row;
    });
    setFeedGroups(data);
  };

  return (
    <section className='feed-group-management-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <StyledTableCell align='center' component='th' scope='row'>
              {row.name}
            </StyledTableCell>
            <StyledTableCell align='center'>{row.animalType || "Empty"}</StyledTableCell>
            <StyledTableCell align='center'>{row.animalSubtype || "Empty"}</StyledTableCell>
            <EditableSelectCell row={row} name='Site' data={row.siteId} property='siteId' menuItems={sites} />
            <StyledTableCell
              align='center'
              className='feed-group-management-table__feed-plan-summary'
              onClick={() => navigateToFeedPlanSummary(row.id)}
            >
              Feed Plan Summary
            </StyledTableCell>
            <EditableSelectCell row={row} name='Barn' data={row.barnId} property='barnId' menuItems={barns} />
            <StyledTableCell align='center'>{row.count}</StyledTableCell>
            <EditableMultipleSelectCell row={row} name='Bins' data={row.bins} property='binIds' menuItems={bins} />
            <EditableDatePickerCell
              row={row}
              name='Start Date'
              data={new Date(row.startDate)}
              property='startDate'
              maxDate={new Date(row?.minDate || row.startDate)}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableDatePickerCell
              row={row}
              name='End Date'
              data={new Date(row.endDate)}
              minDate={new Date(row?.maxDate || row.endDate)}
              property='endDate'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<EditIcon />}
                onClick={() => handleOpenModal(row.count, row.id)}
              >
                Edit
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
      {animalMovementOpen && (
        <AnimalMovementModal
          open={animalMovementOpen}
          setOpen={setAnimalMovementOpen}
          id={feedGroupId}
          count={animalCount}
          fetchItems={fetchFeedGroups}
        />
      )}
    </section>
  );
}
