import { Button, IconButton, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import useAxios from "../../../hooks/use-axios.hook";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { useSnackBar } from "../../../providers";
import { SetupBarnsTable } from "./setup-barns-table/setup-barns-table";
import { Barn } from "../../../interfaces";
import ImportBarnInstruction from "./import-barn-instructions-modal/import-barn-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";

export function SetupBarns(): JSX.Element {
  const headers = ["Name", "Site", "Remove"];

  const [barns, setBarns] = useState<Barn[]>([]);
  const [changedRows, setChangedRows] = useState<Barn[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importBarnOpen, setImportBarnOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [barnsFile, setBarnsFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchBarns();
  }, []);

  const refresh = (): void => {
    fetchBarns("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchBarns("Successfully cancelled!");
  };

  const fetchBarns = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Barn[]>({ url: "/client/barns" });

      setBarns(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateBarns = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);
      setBarnsFile(formData);
      const validateData = async (): Promise<void> => {
        await post({
          url: "/client/barns/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadBarns(formData);
          setImportBarnOpen(false);
        })
        .catch((error) => {
          setImportBarnOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const onAddChanged = (row: Barn, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as Barn[]);
  };

  const add = (): void => {
    const data = [...barns];
    const barnsData = [...changedRows];

    const barn: Barn = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      siteId: data[0]?.siteId,
      site: data[0]?.site,
      toDelete: false,
      isAdded: true,
      bins: data[0]?.bins,
    };

    barn.subId = barn.id;
    data.push(barn);
    barnsData.push(barn);

    setIsReadyForSave(false);
    setBarns(data);
    setChangedRows(barnsData);
  };

  const importBarns = (importedBarns: Barn[]): void => {
    let data = [...barns];
    let barnsData = [...changedRows];
    importedBarns = importedBarns.map((barn) => {
      barn.id = generatedId(36);
      barn.subId = barn.id;
      barn.isAdded = true;
      barn.toDelete = false;
      return barn;
    });

    data = data.concat(importedBarns);
    barnsData = barnsData.concat(importedBarns);

    setIsReadyForSave(false);
    setBarns(data);
    setChangedRows(barnsData);
  };

  const uploadBarns = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<Barn[]>({
        url: "/client/barns/csv",
        data: formData || barnsFile,
      });
      importBarns(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const getFinalSetupBarns = (): void => {
    let data = [...changedRows];

    data = data.map((barn) => {
      if (barn.isAdded) {
        barn.id = null;
      }

      return barn;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupBarns();

    const postData = async (): Promise<void> => {
      await post<Barn[]>({ url: "/client/barns/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchBarns("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((barn) => {
      if (barn.isAdded && !barn?.id) {
        barn.id = barn.subId;
      }

      return barn;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportBarnsModal = (): void => {
    setImportBarnOpen(true);
  };

  const handleBarnsSetupComplete = (): void => {
    handleSetupComplete("BARNS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import System Barns'>
          <IconButton onClick={openImportBarnsModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleBarnsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupBarnsTable
        headers={headers}
        rows={barns}
        setBarns={setBarns}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Barns'
        addButtonLabel='Add Setup Barns'
        refreshButtonLabel='Refresh Setup Barns'
        cancelButtonLabel='Discard changes'
      />

      <ImportBarnInstruction open={importBarnOpen} setOpen={setImportBarnOpen} uploadBarns={validateBarns} />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadBarns()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
