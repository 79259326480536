const prefix = "feed-planner_";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storageType: Storage | any = localStorage;

const set = (key: string, item: unknown | string | number | boolean): void => {
  if (typeof item === "string" || typeof item === "number" || typeof item === "boolean") {
    storageType.setItem(`${prefix}${key}`, item.toString());
  } else {
    storageType.setItem(`${prefix}${key}`, JSON.stringify(item));
  }
};

const get = <T>(key: string): T | string => {
  let value: string;

  try {
    value = JSON.parse(storageType.getItem(`${prefix}${key}`));
  } catch {
    value = storageType.getItem(`${prefix}${key}`);
  }

  return value;
};

const remove = (key: string): void => {
  storageType.removeItem(`${prefix}${key}`);
};

const clear = (): void => {
  storageType.clear();
};

export const storage = {
  set,
  get,
  remove,
  clear,
};

export default storage;
