import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./feed-types-table.scss";
import { FeedType } from "../../../../interfaces";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Dispatch, SetStateAction } from "react";

interface FeedTypesTableProps {
  headers: string[];
  rows: FeedType[] | [];
  loading: boolean;
  setFeedTypes: Dispatch<SetStateAction<FeedType[]>>;
  onAddChanged: (row: FeedType, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function FeedTypesTable({
  headers,
  rows,
  loading,
  setFeedTypes,
  onAddChanged,
  isUnsavedChanges,
}: FeedTypesTableProps): JSX.Element {
  const onSaveChanges = (row: FeedType): void => {
    const data = [...rows];
    data.map((feedType) => {
      if (feedType.id === row.id) {
        feedType = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setFeedTypes(data);
  };

  const removeFeedType = (row: FeedType): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((feedType) => feedType.id !== copyRow?.id);
      setFeedTypes(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='feed-types-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.density}
              property='density'
              name='Density'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='error'
                startIcon={<CloseOutlined />}
                onClick={() => removeFeedType(row)}
              >
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
