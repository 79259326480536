import "./management-dashboard-content.scss";
import { ManagementDashboardContentCards } from "./management-dashboard-content-cards/management-dashboard-content-cards";
import { Grid } from "@mui/material";
import { ManagementDashboardContentIssuesLog } from "./management-dashboard-content-issues-log/management-dashboard-content-issues-log";
import { ManagementDashboardTable } from "./management-dashboard-content-table/management-dashboard-content-table";
import { CardManagement } from "../../../config/card-management";
import { LogDescription, WeeklySnapshot } from "../../../interfaces";

export interface ManagementDashboard {
  cardManagementData: CardManagement[];
  weeklySnapshotHeaders: string[];
  weeklySnapshot: WeeklySnapshot[];
  logs: LogDescription[];
  loading: boolean;
}

export function ManagementDashboardContent({
  cardManagementData,
  weeklySnapshotHeaders,
  weeklySnapshot,
  logs,
  loading,
}: ManagementDashboard): JSX.Element {
  return (
    <div className='management-dashboard-content'>
      <ManagementDashboardContentCards rows={cardManagementData} />
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={8}>
          <ManagementDashboardTable headers={weeklySnapshotHeaders} rows={weeklySnapshot} loading={loading} />
        </Grid>
        <Grid item xs={4}>
          <ManagementDashboardContentIssuesLog logs={logs} />
        </Grid>
      </Grid>
    </div>
  );
}
