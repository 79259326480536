import { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import "./production-sequence-table.scss";
import { ProductionSequenceProps } from "../../../interfaces";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Dispatch, SetStateAction } from "react";
import { CircularProgress, TableBody } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import DragAndDropTable from "../../../components/table/drag-and-drop-table/drag-and-drop-table";

interface ProductionSequenceTableProps {
  headers: string[];
  rows: ProductionSequenceProps[];
  loading: boolean;
  isUnsavedChanges: boolean;
  changedRows: ProductionSequenceProps[];
  setProductionSequence: Dispatch<SetStateAction<ProductionSequenceProps[]>>;
  setIsReadyForSave: Dispatch<SetStateAction<boolean>>;
  setChangedRows: Dispatch<SetStateAction<ProductionSequenceProps[]>>;
}

export function ProductionSequenceTable({
  headers,
  rows,
  loading,
  isUnsavedChanges,
  setProductionSequence,
  setIsReadyForSave,
  changedRows,
  setChangedRows,
}: ProductionSequenceTableProps): JSX.Element {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setProductionSequence((prev: ProductionSequenceProps[]) => {
      let temp = [...prev];
      if (result?.destination?.index) {
        const d = { ...temp[result.destination.index] };
        temp[result.destination.index] = { ...temp[result.source.index] };
        temp[result.source.index] = { ...d };
      }
      temp = handleSequenceOrder(temp);
      if (result?.destination?.index) {
        handleSaveItems([temp[result.destination.index], temp[result.source.index]]);
      }

      return temp;
    });
  };

  const handleSaveItems = (types: ProductionSequenceProps[]): void => {
    let data = [...changedRows];

    if (!data.length) {
      data = data.concat(types);
    } else {
      data = data.map((feedPlanSequenceItem) => {
        for (const type of types) {
          if (feedPlanSequenceItem.id === type.id) {
            feedPlanSequenceItem = { ...type };
          }
        }
        return feedPlanSequenceItem;
      });
      types.forEach((type) => {
        if (data.filter((feedPlanSequenceItem) => feedPlanSequenceItem?.id === type?.id)?.length === 0) {
          data.push(type);
        }
      });
    }

    setIsReadyForSave(false);
    setChangedRows(data);
  };

  const handleSequenceOrder = (temp: ProductionSequenceProps[]): ProductionSequenceProps[] => {
    return temp.map((sequence, index) => {
      sequence.number = index + 1;
      return sequence;
    });
  };

  return (
    <section className='production-sequence-table'>
      <DragAndDropTable headers={headers}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {loading ? (
                  <StyledTableRow className='table-container__row'>
                    <StyledTableCell>
                      <CircularProgress color='success' className='table-container__spinner' />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  rows?.map(
                    (row, index) =>
                      row?.id && (
                        <Draggable key={row.id} draggableId={row.id} index={index}>
                          {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                            return (
                              <StyledTableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                }}
                                className={`${snapshot.isDragging && "production-sequence-table__dragging-row"}`}
                              >
                                <StyledTableCell
                                  component='th'
                                  scope='row'
                                  align='center'
                                  className='production-sequence-table__draggable-cell'
                                >
                                  <div
                                    className='production-sequence-table__draggable-handle'
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <DragHandleIcon
                                      fontSize='medium'
                                      className='production-sequence-table__drag-handle-icon'
                                    />
                                    {row.number}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align='center'>{row?.feedGroup}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.feedType}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.site}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.barn}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.scheduledDate}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.amount}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.loadNumber}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.status}</StyledTableCell>
                                <StyledTableCell align='center'>{row?.daysRemainingInventory}</StyledTableCell>
                              </StyledTableRow>
                            );
                          }}
                        </Draggable>
                      ),
                  )
                )}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </DragAndDropTable>
      {!isUnsavedChanges && (
        <p className='production-sequence-table__warning'>Warning: There are unsaved changes on this page</p>
      )}
    </section>
  );
}
