import { Header } from "../../components/layout/header/header";
import { FeedForecastContent } from "./feed-forecast-content/feed-forecast-content";

export function FeedForecast(): JSX.Element {
  return (
    <div className='feed-forecast'>
      <Header />

      <FeedForecastContent />
    </div>
  );
}
