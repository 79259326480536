import "./import-growth-adjustments-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAxios } from "../../../../hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackBar } from "../../../../providers";
import { useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../../utils/download-file";

interface ImportGrowthAdjustmentInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  uploadGrowthAdjustments: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImportGrowthAdjustmentInstruction = ({
  open,
  setOpen,
  uploadGrowthAdjustments,
}: ImportGrowthAdjustmentInstructionProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/growth-adjustments/csv/template",
        data: null,
      });
      downloadFile(data, "growth-adjustments-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Growth Adjustment Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>name, factor, conditions, start_date, end_date</span>
          <br />
          Where <span className='responsive-dialog__text-bold'>name</span> is the Growth Adjustment Reason/Name,{" "}
          <span className='responsive-dialog__text-bold'>factor</span> is the Growth Factor in percent (%),{" "}
          <span className='responsive-dialog__text-bold'>conditions</span> are the Growth Conditions that must exist for
          this Growth Factor to be applied: Example:{" "}
          <span className='responsive-dialog__text-bold'>animal_type:Beef:animal_type:Poultry</span> and{" "}
          <span className='responsive-dialog__text-bold'>start_date</span> and{" "}
          <span className='responsive-dialog__text-bold'>end_date</span> are the Start & End dates within which the{" "}
          growth adjustments will be applied. Format: DD/MM/YYYY.
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={uploadGrowthAdjustments} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportGrowthAdjustmentInstruction;
