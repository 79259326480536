import { WeeklySnapshot } from "../../../interfaces";
import { generatedId } from "../../../utils/generator-ids";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../table";
import "./weekly-snapshot-table.scss";
import { useState } from "react";
import ConfigTargetModal from "./config-target-modal/config-target-modal";

interface WeeklySnapshotTableProps {
  headers: string[];
  rows: WeeklySnapshot[];
  fetchData?: (message?: string) => void;
  className?: string;
  loading: boolean;
}

export function WeeklySnapshotTable({
  headers,
  rows,
  fetchData,
  className,
  loading,
}: WeeklySnapshotTableProps): JSX.Element {
  const [configTargetOpen, setConfigTargetOpen] = useState(false);

  // const openSettingsModal = (): void => {
  //   setConfigTargetOpen(true);
  // };
  return (
    <section className={`weekly-snapshot-table weekly-snapshot-table--${className}`}>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading}>
        {rows.map((row) => (
          <StyledTableRow key={row.name}>
            <StyledTableCell component='th' scope='row' align='center'>
              {row?.name}
              {/* {row.name === "Target" && !className ? (
                <div className='weekly-snapshot-table__settings'>
                  {row.name}
                  <Tooltip title='Config Target'>
                    <IconButton onClick={openSettingsModal}>
                      <SettingsIcon fontSize='medium' />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <span>{row.name}</span>
              )} */}
            </StyledTableCell>
            {row?.data?.map((item) => (
              <StyledTableCell align='center' key={generatedId(36)}>
                {item}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </CustomizedTable>
      {configTargetOpen && (
        <ConfigTargetModal open={configTargetOpen} setOpen={setConfigTargetOpen} fetchData={fetchData} />
      )}
    </section>
  );
}
