import "./setup-barns-table.scss";

import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Barn, Site } from "../../../../interfaces";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";

interface SetupBarnsTableProps {
  headers: string[];
  rows: Barn[] | [];
  loading: boolean;
  setBarns: Dispatch<SetStateAction<Barn[]>>;
  onAddChanged: (row: Barn, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function SetupBarnsTable({
  headers,
  rows,
  loading,
  setBarns,
  onAddChanged,
  isUnsavedChanges,
}: SetupBarnsTableProps): JSX.Element {
  const [sites, setSites] = useState<Site[] | []>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleBarnSites();
  }, []);

  const handleBarnSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: Barn): void => {
    const data = [...rows];
    data.map((barn) => {
      if (barn.id === row.id) {
        barn = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setBarns(data);
  };

  const removeBarn = (row: Barn): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((barn) => barn.id !== copyRow?.id);
      setBarns(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='setup-barns-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows &&
          rows.map((row, index) => (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableTableCell
                row={row}
                data={row.name}
                property='name'
                name='Name'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableSelectCell
                row={row}
                name='Site'
                data={row.siteId}
                property='siteId'
                menuItems={sites}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button variant='contained' color='error' startIcon={<CloseOutlined />} onClick={() => removeBarn(row)}>
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </CustomizedTable>
    </section>
  );
}
