import { ManagementDashboardCardItems } from "../../../../../config/card-management";
import "./management-dashboard-content-card.scss";

interface ManagementDashboardCardProps {
  item: ManagementDashboardCardItems;
  index: number;
}

export function ManagementDashboardContentCard({ item, index }: ManagementDashboardCardProps): JSX.Element {
  return (
    <div
      key={item.label}
      className={`management-dashboard-content-card__item ${
        item.color === "success" && "management-dashboard-content-card__item--success"
      } ${item.color === "warning" && "management-dashboard-content-card__item--warning"} ${
        item.color === "danger" && "management-dashboard-content-card__item--danger"
      } ${index === 0 && "management-dashboard-content-card__item-first-child"}`}
    >
      <span className='management-dashboard-content-card__label'>{item.label}</span>
      <span className='management-dashboard-content-card__label'>
        {item.value} {item.isPercentage && "%"}
      </span>
    </div>
  );
}
