import { FeedGroup } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import "./feed-group-detail-table.scss";

interface FeedGroupDetailTableProps {
  headers: string[];
  row: FeedGroup;
  loading: boolean;
}

export function FeedGroupDetailTable({ headers, row, loading }: FeedGroupDetailTableProps): JSX.Element {
  const navigate = useNavigate();

  const navigateToFeedPlanSummary = (id: string | null): void => {
    navigate(`/feed-group-management/feed-plan-summary/${id}`);
  };

  return (
    <div className='feed-group-feed-detail-table'>
      <CustomizedTable headers={headers} isEmpty={!row} loading={loading}>
        <StyledTableRow key={row?.id}>
          <StyledTableCell component='th' scope='row' align='center'>
            {row.name}
          </StyledTableCell>
          <StyledTableCell align='center'>{row.animalType?.name || "Empty"}</StyledTableCell>
          <StyledTableCell align='center'>{row.animalSubtype?.subtype || "Empty"}</StyledTableCell>
          <StyledTableCell align='center'>{row.site.name}</StyledTableCell>
          <StyledTableCell align='center'>{row.barn.name}</StyledTableCell>
          <StyledTableCell align='center'>
            {row.bins
              .map((bin) => {
                return bin.name;
              })
              .join(", ")}
          </StyledTableCell>
          <StyledTableCell align='center'>{row.count}</StyledTableCell>
          <StyledTableCell
            align='center'
            className='feed-group-feed-detail-table__feed-plan-summary'
            onClick={() => navigateToFeedPlanSummary(row.id)}
          >
            Feed Plan Summary
          </StyledTableCell>
        </StyledTableRow>
      </CustomizedTable>
    </div>
  );
}
