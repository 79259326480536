import "./config-target-modal.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";
import { FeedTarget } from "./feed-target/feed-target";

interface ConfigTargetModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchData?: (message?: string) => void;
}

export interface FeedTargetProps {
  dayOfWeek: string;
  dailyProductionTarget: number;
  override: boolean;
}

const ConfigTargetModal = ({ open, setOpen, fetchData }: ConfigTargetModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [feedTargets, setFeedTargets] = useState<FeedTargetProps[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { put, get } = useAxios();

  useEffect(() => {
    fetchFeedTargets();
  }, []);

  const handleClose = (): void => {
    setOpen(false);
  };

  const fetchFeedTargets = (): void => {
    const fetchData = async (): Promise<void> => {
      const response = await get<FeedTargetProps[]>({
        url: "/client/companies/feed-targets",
      });
      setFeedTargets(response);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleUpdate = (): void => {
    const postData = async (): Promise<void> => {
      await put<FeedTargetProps[]>({
        url: "/client/companies/feed-targets",
        data: { data: feedTargets },
      });

      if (fetchData) {
        fetchData("Successfully updated!");
      } else {
        openSnackBarMessage("Successfully updated!", "success");
      }
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onChangeFeedTarget = (feedTarget: FeedTargetProps): void => {
    let data = [...feedTargets];
    data = data.map((target) => {
      if (target.dayOfWeek === feedTarget.dayOfWeek) {
        target = feedTarget;
      }
      return target;
    });
    setFeedTargets(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Daily Production Target Config
      </DialogTitle>

      <DialogContent>
        {feedTargets.map((feedTarget) => (
          <FeedTarget key={feedTarget.dayOfWeek} feedTarget={feedTarget} onChangeFeedTarget={onChangeFeedTarget} />
        ))}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleUpdate} className='responsive-dialog__button-update'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfigTargetModal;
