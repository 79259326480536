import { Alert, AlertColor, Snackbar, SnackbarOrigin } from "@mui/material";
import { createContext, useContext, useState } from "react";

import { WithChildren } from "../interfaces";
import { environment } from "../environments/environment";

interface SnackbarContextInterface {
  openSnackBarMessage: (message: string, state: AlertColor | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SnackbarContext = createContext<SnackbarContextInterface>(null!);

export const SnackbarProvider = ({ children }: WithChildren) => {
  const [message, setMessage] = useState("");
  const [snackState, setSnackState] = useState<AlertColor | undefined>(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
    setMessage("");
    setSnackState(undefined);
  };

  const openSnackBarMessage = (message: string, state: AlertColor | undefined) => {
    setSnackState(state);
    setMessage(message);
    setOpenSnackBar(!openSnackBar);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackBarMessage }}>
      {children}
      <Snackbar
        open={openSnackBar}
        anchorOrigin={environment.snackbarAnchorOrigin as SnackbarOrigin}
        autoHideDuration={environment.snackBarAutoHideDuration}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackState} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackBar = (): SnackbarContextInterface => useContext(SnackbarContext);
