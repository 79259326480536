/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useRef } from "react";

const TIMEOUT_VAR = 200;
const FIRST_CLICK = 1;
const DOUBLE_CLICK = 0;

export const useDoubleClick = (
  doubleClick: (event: MouseEvent | any) => void,
  click: (event: MouseEvent | any) => void,
  timeout = TIMEOUT_VAR,
) => {
  // we're using useRef here for the useCallback to remember the timeout
  const clickTimeout = useRef<NodeJS.Timeout | undefined>();

  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = undefined;
    }
  };

  // return a memoized version of the callback that only changes if one of the dependencies has changed
  return useCallback(
    (event: MouseEvent | any) => {
      clearClickTimeout();
      if (click && event.detail === FIRST_CLICK) {
        clickTimeout.current = setTimeout(() => {
          click(event);
        }, timeout);
      }
      if (event.detail % 2 === DOUBLE_CLICK) {
        doubleClick(event);
      }
    },
    [click, doubleClick],
  );
};
