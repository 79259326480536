import "./bin-management-update-modal.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { EditableTableCell } from "../../../components/table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow } from "../../../components/table/table";
import { getFormattedDate } from "../../../utils/formatted-date";
import { DateTimePicker } from "../../../components/date-picker/date-picker";
interface BinManagementUpdateProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchBinManagement: () => void;
  id: string;
}

interface BinManagementInventory {
  inventory: number;
  date: string | Date;
}

const BinManagementUpdateModal = ({ open, setOpen, fetchBinManagement, id }: BinManagementUpdateProps) => {
  const theme = useTheme();
  const headers = ["Inventory (MT)", "Date"];
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [data, setData] = useState<BinManagementInventory>({ inventory: 0, date: new Date(currentDate) });

  const { openSnackBarMessage } = useSnackBar();
  const { post, get } = useAxios();

  useEffect(() => {
    if (id && currentDate.toString() !== null) {
      setData({ inventory: 0, date: getFormattedDate(currentDate) });
      fetchBinInventory(getFormattedDate(currentDate));
    }
  }, [id]);

  const handleClose = (): void => {
    setOpen(false);
  };

  const fetchBinInventory = (activeDate: string): void => {
    const fetchData = async (): Promise<void> => {
      const response = await get<number>({
        url: `/client/bins/${id}/inventory?date=${activeDate}`,
      });

      setData({ inventory: response, date: activeDate });
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleUpdate = (): void => {
    const postData = async (): Promise<void> => {
      await post<BinManagementInventory[]>({
        url: `/client/bins/${id}/inventory`,
        data: data,
      });

      fetchBinManagement();
      openSnackBarMessage("Successfully updated!", "success");
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onSaveChanges = (data: BinManagementInventory): void => {
    setData(data);
  };

  const onChangeCurrentDate = (date: Date | null): void => {
    if (date) {
      setData({ inventory: data.inventory, date });
      setCurrentDate(date);
      fetchBinInventory(getFormattedDate(date));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Update Record
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers}>
          <StyledTableRow>
            <EditableTableCell
              row={data}
              name='Inventory'
              data={data.inventory}
              property='inventory'
              onSaveChanges={onSaveChanges}
            />
            <DateTimePicker
              dateTime={currentDate}
              disableFuture
              disablePast={false}
              name='Current Date'
              onChangeDate={onChangeCurrentDate}
            />
          </StyledTableRow>
        </CustomizedTable>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleUpdate} className='responsive-dialog__button-update'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BinManagementUpdateModal;
