import { Button } from "@mui/material";

import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { SiteCombination } from "../../../interfaces";
import { SetupSiteCombinationTable } from "./site-combinations-table/site-combinations-table";
import { handleAddChanged } from "../../../utils/check-add";

export function SetupSiteCombinations(): JSX.Element {
  const headers = ["Combination Name", "Sites Combined", "Remove"];

  const [siteCombinations, setSiteCombinations] = useState<SiteCombination[]>([]);
  const [changedRows, setChangedRows] = useState<SiteCombination[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchSiteCombinations();
  }, []);

  const refresh = (): void => {
    fetchSiteCombinations("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchSiteCombinations("Successfully cancelled!");
  };

  const fetchSiteCombinations = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<SiteCombination[]>({ url: "/client/site-combinations" });

      setSiteCombinations(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: SiteCombination, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as SiteCombination[]);
  };

  const add = (): void => {
    const data = [...siteCombinations];
    const siteCombinationsData = [...changedRows];

    const siteCombination: SiteCombination = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      siteIds: data[0]?.siteIds,
      sites: data[0]?.sites,
      toDelete: false,
      isAdded: true,
    };

    siteCombination.subId = siteCombination.id;
    data.push(siteCombination);
    siteCombinationsData.push(siteCombination);

    setIsReadyForSave(false);
    setSiteCombinations(data);
    setChangedRows(siteCombinationsData);
  };

  const getFinalSetupSiteCombinations = (): void => {
    let data = [...changedRows];

    data = data.map((site) => {
      if (site.isAdded) {
        site.id = null;
      }

      return site;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupSiteCombinations();

    const postData = async (): Promise<void> => {
      await post<SiteCombination[]>({ url: "/client/site-combinations/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchSiteCombinations("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    const data = [...changedRows];

    data.map((site) => {
      if (site.isAdded && !site?.id) {
        site.id = site.subId;
      }

      return site;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const handleSiteCombinationsSetupComplete = (): void => {
    handleSetupComplete("SITE_COMBINATIONS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Button variant='contained' color='primary' onClick={handleSiteCombinationsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupSiteCombinationTable
        headers={headers}
        rows={siteCombinations}
        setSiteCombinations={setSiteCombinations}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Site Combination'
        addButtonLabel='Add Site Combination'
        refreshButtonLabel='Refresh Site Combination'
        cancelButtonLabel='Discard changes'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
