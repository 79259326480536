import "./setup-growth-curves-table.scss";

import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { Dispatch, SetStateAction } from "react";

import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { GrowthCurve } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";

interface SetupGrowthCurvesTableProps {
  headers: string[];
  rows: GrowthCurve[] | [];
  loading: boolean;
  setGrowthCurves: Dispatch<SetStateAction<GrowthCurve[]>>;
  onAddChanged: (row: GrowthCurve, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function SetupGrowthCurvesTable({
  headers,
  rows,
  loading,
  setGrowthCurves,
  onAddChanged,
  isUnsavedChanges,
}: SetupGrowthCurvesTableProps): JSX.Element {
  const navigate = useNavigate();

  const onSaveChanges = (row: GrowthCurve): void => {
    const data = [...rows];
    data.map((growthCurve) => {
      if (growthCurve.id === row.id) {
        growthCurve = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setGrowthCurves(data);
  };

  const removeGrowthCurve = (row: GrowthCurve): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((growthCurve) => growthCurve.id !== copyRow?.id);
      setGrowthCurves(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToGrowthCurveSequence = (id: string | null): void => {
    navigate(`/system-setup/growth-curves-sequence/${id}`);
  };
  return (
    <section className='setup-barns-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows &&
          rows.map((row, index) => (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableTableCell
                row={row}
                data={row.name}
                property='name'
                name='Name'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                data={row.description}
                property='description'
                name='Description'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<EditIcon />}
                  onClick={() => navigateToGrowthCurveSequence(row.id)}
                >
                  Edit
                </Button>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='error'
                  startIcon={<CloseOutlined />}
                  onClick={() => removeGrowthCurve(row)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </CustomizedTable>
    </section>
  );
}
