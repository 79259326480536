import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./feed-plan-sequence-table.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FeedPlanSequenceType, FeedType } from "../../../../../interfaces";
import { EditableTableCell } from "../../../../../components/table/editable-input-cell/editable-input-cell";
import { StyledTableRow, StyledTableCell } from "../../../../../components/table/table";
import { EditableSelectCell } from "../../../../../components/table/editable-select-cell/editable-select-cell";
import { useAxios } from "../../../../../hooks";
import { useSnackBar } from "../../../../../providers";
import { useNavigate } from "react-router-dom";
import DragAndDropTable from "../../../../../components/table/drag-and-drop-table/drag-and-drop-table";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { TableBody } from "@mui/material";

interface FeedPlanSequenceTableProps {
  headers: string[];
  feedPlanName: string;
  feedPlanSequence: FeedPlanSequenceType[];
  changedRows: FeedPlanSequenceType[];
  setIsReadyForSave: Dispatch<SetStateAction<boolean>>;
  isUnsavedChanges: boolean;
  setChangedRows: Dispatch<SetStateAction<FeedPlanSequenceType[]>>;
  setFeedPlanSequence: Dispatch<SetStateAction<FeedPlanSequenceType[]>>;
  onAddChanged: (row: FeedPlanSequenceType, isDeleted?: boolean, isModified?: boolean) => void;
}

export function FeedPlanSequenceTable({
  headers,
  feedPlanName,
  feedPlanSequence,
  changedRows,
  setIsReadyForSave,
  setChangedRows,
  setFeedPlanSequence,
  onAddChanged,
  isUnsavedChanges,
}: FeedPlanSequenceTableProps): JSX.Element {
  const [feedTypes, setFeedTypes] = useState<FeedType[] | []>([]);
  const navigate = useNavigate();

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedTypes();
  }, []);

  const handleFeedTypes = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedType[]>({ url: "/client/feed-types" });

      setFeedTypes(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: FeedPlanSequenceType): void => {
    const data = [...feedPlanSequence];
    data.map((item) => {
      if (item.id === row.id) {
        item = row;
        onAddChanged(row, false, true);
      }
      return row;
    });
    setFeedPlanSequence(data);
  };

  const removeFeedPlanSequence = (row: FeedPlanSequenceType): void => {
    let data = [...feedPlanSequence];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((item) => item.id !== copyRow?.id);
      setFeedPlanSequence(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToFeedPlans = (): void => {
    navigate("/system-setup/feed-plans");
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setFeedPlanSequence((prev: FeedPlanSequenceType[]) => {
      let temp = [...prev];
      if (result?.destination?.index) {
        const d = { ...temp[result.destination.index] };
        temp[result.destination.index] = { ...temp[result.source.index] };
        temp[result.source.index] = { ...d };
      }
      temp = handleSequenceOrder(temp);
      if (result?.destination?.index) {
        handleSaveItems([temp[result.destination.index], temp[result.source.index]]);
      }

      return temp;
    });
  };

  const handleSaveItems = (types: FeedPlanSequenceType[]): void => {
    let data = [...changedRows];

    if (!data.length) {
      data = data.concat(types);
    } else {
      data = data.map((feedPlanSequenceItem) => {
        for (const type of types) {
          if (feedPlanSequenceItem.id === type.id) {
            feedPlanSequenceItem = type;
          }
        }
        return feedPlanSequenceItem;
      });
      types.forEach((type) => {
        if (data.filter((feedPlanSequenceItem) => feedPlanSequenceItem?.id === type?.id)?.length === 0) {
          data.push(type);
        }
      });
    }

    setIsReadyForSave(false);
    setChangedRows(data);
  };

  const handleSequenceOrder = (temp: FeedPlanSequenceType[]): FeedPlanSequenceType[] => {
    return temp.map((sequence, index) => {
      sequence.number = index + 1;
      return sequence;
    });
  };
  return (
    <section className='feed-plan-sequence-table'>
      <div className='feed-plan-sequence-table__header' onClick={navigateToFeedPlans}>
        <ArrowBackIcon fontSize='medium' className='feed-plan-sequence-table__back-icon' />
        <p className='feed-plan-sequence-table__title'>Edit {feedPlanName}</p>
      </div>
      <DragAndDropTable headers={headers}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {feedPlanSequence.map(
                  (row: FeedPlanSequenceType, index: number) =>
                    row?.id && (
                      <Draggable key={row.id} draggableId={row.id} index={index}>
                        {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                          return (
                            <StyledTableRow
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                              }}
                              className={`${snapshot.isDragging && "feed-plan-sequence-table__dragging-row"}`}
                            >
                              <StyledTableCell
                                component='th'
                                scope='row'
                                align='center'
                                className='feed-plan-sequence-table__draggable-cell'
                              >
                                <div
                                  className='feed-plan-sequence-table__draggable-handle'
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <DragHandleIcon
                                    fontSize='medium'
                                    className='feed-plan-sequence-table__drag-handle-icon'
                                  />
                                  {row.number}
                                </div>
                              </StyledTableCell>
                              <EditableSelectCell
                                row={row}
                                data={row.feedTypeId}
                                property='feedTypeId'
                                name='Feed Type'
                                menuItems={feedTypes}
                                isUnsavedChanges={isUnsavedChanges}
                                onSaveChanges={onSaveChanges}
                              />
                              <EditableTableCell
                                row={row}
                                data={row.kgsPerHead}
                                property='kgsPerHead'
                                name='Kgs per Head'
                                isUnsavedChanges={isUnsavedChanges}
                                onSaveChanges={onSaveChanges}
                              />
                              <StyledTableCell align='center'>
                                <Button
                                  variant='outlined'
                                  startIcon={<CloseOutlined />}
                                  onClick={() => removeFeedPlanSequence(row)}
                                >
                                  Remove
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }}
                      </Draggable>
                    ),
                )}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </DragAndDropTable>
      {!isUnsavedChanges && (
        <p className='feed-plan-sequence-table__warning'>Warning: There are unsaved changes on this page</p>
      )}
    </section>
  );
}
