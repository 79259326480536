import "./footer.scss";

import { IconButton, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import RefreshIcon from "@mui/icons-material/Refresh";

interface FooterProps {
  onSave?: () => void;
  onAdd?: () => void;
  onRefresh?: () => void;
  onCancel?: () => void;
  saveButtonDisabled?: boolean;
  addButtonDisabled?: boolean;
  refreshButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  saveButtonLabel?: string;
  addButtonLabel?: string;
  refreshButtonLabel?: string;
  cancelButtonLabel?: string;
}

export const Footer = ({
  onSave,
  onAdd,
  onRefresh,
  onCancel,
  saveButtonDisabled,
  addButtonDisabled,
  refreshButtonDisabled,
  cancelButtonDisabled,
  saveButtonLabel,
  addButtonLabel,
  refreshButtonLabel,
  cancelButtonLabel,
}: FooterProps): JSX.Element => {
  return (
    <div className='footer'>
      <div className='footer__actions'>
        {onSave && saveButtonLabel && (
          <Tooltip title={saveButtonLabel}>
            <IconButton disabled={saveButtonDisabled} className='footer__button' onClick={onSave}>
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}

        {onAdd && addButtonLabel && (
          <Tooltip title={addButtonLabel}>
            <IconButton
              disabled={addButtonDisabled}
              className='footer__button footer__button--left-spacing'
              onClick={onAdd}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}

        {onRefresh && refreshButtonLabel && (
          <Tooltip title={refreshButtonLabel}>
            <IconButton
              disabled={refreshButtonDisabled}
              className={`footer__button ${onSave && onAdd && "footer__button--left-spacing"}`}
              onClick={onRefresh}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}

        {onCancel && cancelButtonLabel && (
          <Tooltip title={cancelButtonLabel}>
            <IconButton
              disabled={cancelButtonDisabled}
              className='footer__button footer__button--left-spacing'
              onClick={onCancel}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
