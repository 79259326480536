import "./animal-movement-dialog.scss";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { EditableTableCell } from "../table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../table/table";
import { FeedGroupManagementType } from "../../interfaces";
import { EditableSelectCell } from "../table/editable-select-cell/editable-select-cell";

interface AnimalMovementProps {
  open: boolean;
  id: string;
  count: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchItems: () => void;
}

const AnimalMovementModal = ({ open, count, id, setOpen, fetchItems }: AnimalMovementProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [headers, setHeaders] = useState(["Count", "Type"]);
  const types = ["DEATH", "CORRECTION", "TRANSFER"];
  const [currentType, setCurrentType] = useState("DEATH");
  const [animalCount, setAnimalCount] = useState({ count });
  const [currentFeedGroup, setCurrentFeedGroup] = useState({ feedGroup: "" });
  const [feedGroups, setFeedGroups] = useState<FeedGroupManagementType[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { post, get } = useAxios();

  useEffect(() => {
    handleFeedGroup();
    setAnimalCount({ count });
  }, [count, id]);

  const handleFeedGroup = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroupManagementType[]>({ url: "/client/feed-groups" });

      setFeedGroups(data);
      if (data[0]?.id) {
        setCurrentFeedGroup({ feedGroup: data[0]?.id });
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleUpdate = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedGroupManagementType[]>({
        url: `/client/feed-groups/${id}/animal-movement`,
        data: {
          count: animalCount?.count,
          type: currentType,
          feedGroupId: currentType === "TRANSFER" ? currentFeedGroup?.feedGroup : null,
        },
      });

      fetchItems();
      openSnackBarMessage("Successfully updated!", "success");
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onChange = (e: SelectChangeEvent<string>): void => {
    setCurrentType(e.target.value);
    if (e.target.value === "TRANSFER") {
      setHeaders(["Count", "Type", "Group"]);
    } else {
      setHeaders(["Count", "Type"]);
    }
  };

  const onSaveChanges = (data: { count: number }): void => {
    setAnimalCount(data);
  };

  const onSaveFeedGroupChanges = (data: { feedGroup: string }): void => {
    setCurrentFeedGroup(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Update Animal Movement
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers}>
          <StyledTableRow>
            <EditableTableCell
              row={animalCount}
              name='Count'
              data={animalCount.count}
              property='count'
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center' className='editable-field'>
              <FormControl variant='standard'>
                <Select value={currentType} label='Type' onChange={(e) => onChange(e)}>
                  {types &&
                    types.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </StyledTableCell>
            {currentType === "TRANSFER" && (
              <EditableSelectCell
                row={currentFeedGroup}
                name='Site'
                data={currentFeedGroup.feedGroup}
                property='feedGroup'
                menuItems={feedGroups}
                onSaveChanges={onSaveFeedGroupChanges}
              />
            )}
          </StyledTableRow>
        </CustomizedTable>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleUpdate} className='responsive-dialog__button-update'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AnimalMovementModal;
