import {
  CalculateOutlined,
  FoodBankOutlined,
  AccessTimeOutlined,
  TimelineOutlined,
  PersonAddAlt1Outlined,
  InventoryOutlined,
} from "@mui/icons-material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { SidebarMenuItemIcon } from "../interfaces";

export const menuItems: SidebarMenuItemIcon[] = [
  {
    Icon: CalculateOutlined,
    link: "/bin-management",
  },
  {
    Icon: FoodBankOutlined,
    link: "/feed-group-management",
  },
  {
    Icon: AccessTimeOutlined,
    link: "/feed-forecast",
  },
  {
    Icon: TimelineOutlined,
    link: "/feed-orders",
  },
  {
    Icon: PersonAddAlt1Outlined,
    link: "/user-admin",
  },
  {
    Icon: ManageHistoryIcon,
    link: "/production-sequence",
  },
  {
    Icon: InventoryOutlined,
    link: "/management-dashboard",
  },
];
