import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import { Footer } from "../../../../components/layout/footer/footer";
import { Header } from "../../../../components/layout/header/header";
import { useAxios } from "../../../../hooks";
import { FeedPlan, FeedPlanSequenceType } from "../../../../interfaces";
import { useSnackBar } from "../../../../providers";
import { generatedId } from "../../../../utils/generator-ids";
import { useNavigate, useParams } from "react-router-dom";
import { FeedPlanSequenceTable } from "./feed-plan-sequence-table/feed-plan-sequence-table";
import ImportFeedPlanSequenceInstruction from "./import-feed-plan-sequence-instructions-modal/import-feed-plan-sequence-instructions-modal";
import { handleSetupComplete } from "../../../../utils/setup-complete";
import { handleAddChanged } from "../../../../utils/check-add";

export function FeedPlanSequence(): JSX.Element {
  const headers = ["Sequence", "Feed Type", "Kgs per Head", "Remove"];

  const { id } = useParams();
  const [feedPlanSequence, setFeedPlanSequence] = useState<FeedPlanSequenceType[]>([]);
  const [changedRows, setChangedRows] = useState<FeedPlanSequenceType[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importFeedPlanSequenceOpen, setImportFeedPlanSequenceOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [feedPlanName, setFeedPlanName] = useState("");

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchFeedPlanSequence();
    fetchFeedPlan();
  }, []);

  const refresh = (): void => {
    fetchFeedPlanSequence("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedPlanSequence("Successfully cancelled!");
  };

  const fetchFeedPlan = (message?: string): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedPlan>({
        url: `/client/feed-plans/${id}`,
      });

      setFeedPlanName(data?.name);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchFeedPlanSequence = (message?: string): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedPlanSequenceType[]>({
        url: `/client/feed-plans/${id}/items`,
      });

      setFeedPlanSequence(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onAddChanged = (row: FeedPlanSequenceType, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as FeedPlanSequenceType[]);
  };

  const add = (): void => {
    const data = [...feedPlanSequence];
    const feedPlanSequenceData = [...changedRows];
    const nextNumber = feedPlanSequence[feedPlanSequence?.length - 1]?.number + 1;

    const feedPlanSequenceItem: FeedPlanSequenceType = {
      id: generatedId(36),
      subId: "",
      number: isNaN(nextNumber) ? 1 : nextNumber,
      feedTypeId: feedPlanSequence[0]?.feedTypeId,
      feedType: feedPlanSequence[0]?.feedType,
      kgsPerHead: 0,
      toDelete: false,
      isAdded: true,
    };

    feedPlanSequenceItem.subId = feedPlanSequenceItem.id;
    data.push(feedPlanSequenceItem);
    feedPlanSequenceData.push(feedPlanSequenceItem);

    setIsReadyForSave(false);
    setFeedPlanSequence(data);
    setChangedRows(feedPlanSequenceData);
  };

  const importFeedPlanSequence = (importedFeedPlanSequence: FeedPlanSequenceType[]): void => {
    let data = [...feedPlanSequence];
    let feedPlanSequenceData = [...changedRows];
    importedFeedPlanSequence = importedFeedPlanSequence.map((feedPlanSequenceItem) => {
      feedPlanSequenceItem.id = generatedId(36);
      feedPlanSequenceItem.subId = feedPlanSequenceItem.id;
      feedPlanSequenceItem.isAdded = true;
      feedPlanSequenceItem.toDelete = false;
      return feedPlanSequenceItem;
    });

    data = data.concat(importedFeedPlanSequence);
    feedPlanSequenceData = feedPlanSequenceData.concat(importedFeedPlanSequence);

    setIsReadyForSave(false);
    setFeedPlanSequence(data);
    setChangedRows(feedPlanSequenceData);
  };

  const save = (): void => {
    getFinalFeedPlanSequence();

    const postData = async (): Promise<void> => {
      await post<FeedPlanSequenceType[]>({
        url: `/client/feed-plans/${id}/items/update`,
        data: { data: changedRows },
      });

      setIsReadyForSave(true);
      fetchFeedPlanSequence("Successfully updated!");
      setChangedRows([]);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((feedPlanSequenceItem) => {
      if (feedPlanSequenceItem.isAdded && !feedPlanSequenceItem?.id) {
        feedPlanSequenceItem.id = feedPlanSequenceItem.subId;
      }

      return feedPlanSequenceItem;
    });

    setChangedRows(data);
  };

  const getFinalFeedPlanSequence = (): void => {
    let data = [...changedRows];
    data = data.map((feedPlanSequenceItem) => {
      if (feedPlanSequenceItem.isAdded) {
        feedPlanSequenceItem.id = null;
      }
      return feedPlanSequenceItem;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportFeedPlanSequenceModal = (): void => {
    setImportFeedPlanSequenceOpen(true);
  };

  const handleSetupFeedPlansComplete = (): void => {
    handleSetupComplete("FEED_PLANS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='feed-plan-sequence'>
      <Header>
        <Tooltip title='Import Feed Plan Sequence'>
          <IconButton onClick={openImportFeedPlanSequenceModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleSetupFeedPlansComplete}>
          Setup Complete
        </Button>
      </Header>

      <FeedPlanSequenceTable
        feedPlanName={feedPlanName}
        headers={headers}
        feedPlanSequence={feedPlanSequence}
        changedRows={changedRows}
        setChangedRows={setChangedRows}
        setIsReadyForSave={setIsReadyForSave}
        setFeedPlanSequence={setFeedPlanSequence}
        onAddChanged={onAddChanged}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Feed Plan Sequence'
        addButtonLabel='Add Feed Plan Sequence'
        refreshButtonLabel='Refresh Feed Plan Sequence'
        cancelButtonLabel='Discard changes'
      />

      <ImportFeedPlanSequenceInstruction
        open={importFeedPlanSequenceOpen}
        setOpen={setImportFeedPlanSequenceOpen}
        importFeedPlanSequence={importFeedPlanSequence}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
