/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { useContext, useMemo, useRef } from "react";

import { AxiosContext } from "../providers";
import { clearStorage, isAuthenticated, useLocalStorage } from "./use-local-storage.hook";

export const useAxios = () => {
  const [token, setToken] = useLocalStorage("accessToken");
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken");

  const contextInstance = useContext(AxiosContext);

  const instance = useMemo(() => {
    return contextInstance || axios;
  }, [contextInstance]);

  const controllerRef = useRef(new AbortController());

  const cancel = (): void => {
    controllerRef.current.abort();
  };

  const getHeaders = async (): Promise<AxiosRequestHeaders> => {
    let headers = {};

    if (isAuthenticated()) {
      const accessToken = token;

      headers = { Authorization: `Bearer ${accessToken}` };
    }

    return headers;
  };

  instance.interceptors.response.use(
    (res: any) => {
      return res;
    },
    async (err: { config: any; response: { status: number } }) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/client/auth/login" && err.response) {
        if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const data = await instance.post(`/client/auth/refresh?token=${refreshToken}`, {
              refreshToken,
            });
            setToken(data.data.accessToken);
            setRefreshToken(data.data.refreshToken);
            return instance(originalConfig);
          } catch (_error) {
            clearStorage();
            window.location.reload();
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    },
  );

  const makeRequest = async <T>(params: AxiosRequestConfig): Promise<T> =>
    await instance
      .request({
        ...params,
        signal: controllerRef.current.signal,
        headers: await getHeaders(),
      })
      .then((response: AxiosResponse<T>) => response.data);

  const getRequest = <T>(params: AxiosRequestConfig): Promise<T> => makeRequest<T>({ ...params, method: "GET" });

  const postRequest = <T>(params: AxiosRequestConfig): Promise<T> => makeRequest<T>({ ...params, method: "POST" });

  const putRequest = <T>(params: AxiosRequestConfig): Promise<T> => makeRequest<T>({ ...params, method: "PUT" });

  const patchRequest = <T>(params: AxiosRequestConfig): Promise<T> => makeRequest<T>({ ...params, method: "PATCH" });

  const deleteRequest = <T>(params: AxiosRequestConfig): Promise<T> => makeRequest<T>({ ...params, method: "DELETE" });

  return { get: getRequest, post: postRequest, put: putRequest, patch: patchRequest, delete: deleteRequest, cancel };
};

export default useAxios;
