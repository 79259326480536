import { Spinner } from "../../components/ui/spinner/spinner";
import { Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useMemo, useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthWrapper } from "../../components/auth-wrapper/auth-wrapper";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const ResetPassword = (): JSX.Element => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const title = "Create New Password";

  const query = useQuery();
  const navigate = useNavigate();

  const { post } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const onChangePassword = (value: string): void => {
    setPassword(value);
  };

  const onChangeConfirmPassword = (value: string): void => {
    setConfirmPassword(value);
  };

  const resetPassword = (): void => {
    setLoading(true);
    const postData = async (): Promise<void> => {
      await post({
        url: `/client/auth/reset-password?token=${query.get("token")}`,
        data: { newPassword: confirmPassword },
      });

      openSnackBarMessage("Successfully changed password!", "success");
      setLoading(false);
      navigate("/login");
    };

    postData().catch((error) => {
      setLoading(false);
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const isDisabledButton = (): boolean => {
    return password?.length < 4 || confirmPassword?.length < 4 || confirmPassword !== password;
  };

  return (
    <AuthWrapper title={title}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl variant='outlined' size='small' className='auth__input' sx={{ mt: 2, mb: 2 }}>
            <LockOpenIcon fontSize='medium' className='auth__icon' />
            <InputLabel htmlFor='password-field' className='auth__label'>
              New Password
            </InputLabel>
            <OutlinedInput
              id='password-field'
              type='password'
              value={password}
              error={!!password && password?.length < 4}
              onChange={(e) => onChangePassword(e.target.value)}
              label='Password'
              aria-describedby='component-error-text'
            />
            {!!password && password?.length < 4 && (
              <FormHelperText id='component-error-text' className='auth__helper'>
                Min Length is 4 characters
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant='outlined' size='small' className='auth__input' sx={{ mt: 2, mb: 2 }}>
            <LockOpenIcon fontSize='medium' className='auth__icon' />
            <InputLabel htmlFor='confirm-password-field' className='auth__label'>
              Confirm Your New Password
            </InputLabel>
            <OutlinedInput
              id='confirm-password-field'
              type='password'
              value={confirmPassword}
              error={!!confirmPassword && (confirmPassword?.length < 4 || confirmPassword !== password)}
              onChange={(e) => onChangeConfirmPassword(e.target.value)}
              label='Password'
              aria-describedby='component-error-text'
            />
            {!!password && password?.length < 4 && (
              <FormHelperText id='component-error-text' className='auth__helper'>
                {!!confirmPassword && confirmPassword?.length < 4 && "Min Length is 4 characters"}
                {!!confirmPassword && confirmPassword !== password && "Passwords must be the same!"}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Button
        className='auth__button'
        variant='contained'
        color='success'
        onClick={resetPassword}
        disabled={isDisabledButton() || isLoading}
      >
        {isLoading ? <Spinner /> : "Done"}
      </Button>
    </AuthWrapper>
  );
};
