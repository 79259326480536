import { Button, IconButton, Tooltip } from "@mui/material";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { handleSetupComplete } from "../../../utils/setup-complete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { FeedOrderAlgorithm, Mill } from "../../../interfaces";
import { SetupMillsTable } from "./setup-mills-table/setup-mills-table";
import { handleAddChanged } from "../../../utils/check-add";
import ImportMillInstruction from "./import-mill-instructions-modal/import-mill-instructions-modal";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";

export function SetupMills(): JSX.Element {
  const headers = [
    "Mill Name",
    "Monday Target",
    "Tuesday Target",
    "Wednesday Target",
    "Thursday Target",
    "Friday Target",
    "Saturday Target",
    "Sunday Target",
    "Feed Order Method",
    "Remove",
  ];

  const [mills, setMills] = useState<Mill[]>([]);
  const [changedRows, setChangedRows] = useState<Mill[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [feedOrderAlgorithm, setFeedOrderAlgorithm] = useState<FeedOrderAlgorithm[]>([]);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [importMillOpen, setImportMillOpen] = useState(false);
  const [millsFile, setMillsFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchMills();
    fetchFeedOrdersAlgorithms();
  }, []);

  const refresh = (): void => {
    fetchMills("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchMills("Successfully cancelled!");
  };

  const fetchFeedOrdersAlgorithms = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedOrderAlgorithm[]>({ url: "/client/feed-orders/algorithms" });

      setFeedOrderAlgorithm(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchMills = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateMills = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setMillsFile(formData);
        await post({
          url: "/client/mills/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadMills(formData);
          setImportMillOpen(false);
        })
        .catch((error) => {
          setImportMillOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadMills = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<Mill[]>({
        url: "/client/mills/csv",
        data: formData || millsFile,
      });
      importMill(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const importMill = (importedMills: Mill[]): void => {
    let data = [...mills];
    let millsData = [...changedRows];
    importedMills = importedMills.map((mill) => {
      mill.id = generatedId(36);
      mill.subId = mill.id;
      mill.isAdded = true;
      mill.toDelete = false;
      return mill;
    });

    data = data.concat(importedMills);
    millsData = millsData.concat(importedMills);

    setIsReadyForSave(false);
    setMills(data);
    setChangedRows(millsData);
  };

  const onAddChanged = (row: Mill, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as Mill[]);
  };

  const add = (): void => {
    const data = [...mills];
    const millsData = [...changedRows];

    const mill: Mill = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      mondayTarget: 0,
      tuesdayTarget: 0,
      wednesdayTarget: 0,
      thursdayTarget: 0,
      fridayTarget: 0,
      saturdayTarget: 0,
      sundayTarget: 0,
      algorithmStrategy: feedOrderAlgorithm[0]?.algorithmStrategy,
      algorithmName: feedOrderAlgorithm[0]?.algorithmName,
      canBeDeleted: true,
      toDelete: false,
      isAdded: true,
    };

    mill.subId = mill.id;
    data.push(mill);
    millsData.push(mill);

    setIsReadyForSave(false);
    setMills(data);
    setChangedRows(millsData);
  };

  const getFinalSetupMill = (): void => {
    let data = [...changedRows];

    data = data.map((mill) => {
      if (mill.isAdded) {
        mill.id = null;
      }

      return mill;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupMill();

    const postData = async (): Promise<void> => {
      await post<Mill[]>({ url: "/client/mills/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchMills("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    const data = [...changedRows];

    data.map((mill) => {
      if (mill.isAdded && !mill?.id) {
        mill.id = mill.subId;
      }

      return mill;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportMillsModal = (): void => {
    setImportMillOpen(true);
  };

  const handleMillsSetupComplete = (): void => {
    handleSetupComplete("MILLS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import Setup Growth Curves'>
          <IconButton onClick={openImportMillsModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleMillsSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupMillsTable
        headers={headers}
        rows={mills}
        feedOrderAlgorithm={feedOrderAlgorithm}
        setMills={setMills}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Site Combination'
        addButtonLabel='Add Site Combination'
        refreshButtonLabel='Refresh Site Combination'
        cancelButtonLabel='Discard changes'
      />
      <ImportMillInstruction open={importMillOpen} setOpen={setImportMillOpen} uploadMills={validateMills} />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadMills()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
