import "./management-dashboard-content-issues-log.scss";
import CheckCircleFilled from "@mui/icons-material/CheckCircle";
import { LogDescription } from "../../../../interfaces";

interface IssuesLogsProps {
  logs: LogDescription[];
}

export function ManagementDashboardContentIssuesLog({ logs }: IssuesLogsProps): JSX.Element {
  return (
    <div className='management-dashboard-content-issues-log'>
      <div
        className={`${
          logs?.length && "management-dashboard-content-issues-log__card--logs"
        } management-dashboard-content-issues-log__card`}
      >
        {logs && logs?.length ? (
          logs?.map((log) => (
            <div key={log?.issueType}>
              <p className='management-dashboard-content-issues-log__log'>
                {log?.issueType}: {log?.count}
              </p>
            </div>
          ))
        ) : (
          <>
            <CheckCircleFilled className='management-dashboard-content-issues-log__icon' />
            <div className='management-dashboard-content-issues-log__title'>Issues Log</div>
            <div className='management-dashboard-content-issues-log__description'>currently no known issues</div>
          </>
        )}
      </div>
    </div>
  );
}
