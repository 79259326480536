import "./setup-animals-table.scss";

import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Payload } from "../../../../interfaces";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";

interface SetupAnimalsTableProps {
  headers: string[];
  rows: SetupAnimal[] | [];
  loading: boolean;
  setAnimals: Dispatch<SetStateAction<SetupAnimal[]>>;
  onAddChanged: (row: SetupAnimal, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export interface SetupAnimal {
  id: string | null;
  subId: string | null;
  animalTypeId: string;
  animalType: AnimalType;
  subtype: string;
  defaultStartingAge: number;
  defaultMortalityRate: number;
  defaultStartingWeight: number;
  defaultAvgWeightVariance: number;
  defaultGrowthCurveId: string;
  defaultGrowthCurve: DefaultGrowthCurve;
  toDelete?: boolean;
  isAdded?: boolean;
}

interface DefaultGrowthCurve {
  data: Payload[];
  count: number;
}

interface AnimalType {
  data: Payload[];
  count: number;
}

export function SetupAnimalsTable({
  headers,
  rows,
  loading,
  setAnimals,
  onAddChanged,
  isUnsavedChanges,
}: SetupAnimalsTableProps): JSX.Element {
  const [animalTypes, setAnimalTypes] = useState<Payload[] | []>([]);
  const [growthCurves, setGrowthCurves] = useState<Payload[] | []>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleAnimalTypes();
    handleGrowthCurves();
  }, []);

  const handleAnimalTypes = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Payload[]>({ url: "/client/animal-types" });

      setAnimalTypes(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleGrowthCurves = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Payload[]>({ url: "/client/growth-curves" });

      setGrowthCurves(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: SetupAnimal): void => {
    const data = [...rows];
    data.map((animal) => {
      if (animal.id === row.id) {
        animal = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setAnimals(data);
  };

  const removeAnimal = (row: SetupAnimal): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((animal) => animal.id !== copyRow?.id);
      setAnimals(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='setup-animals-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows &&
          rows.map((row, index) => (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableSelectCell
                row={row}
                name='Type'
                data={row.animalTypeId}
                property='animalTypeId'
                menuItems={animalTypes}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                data={row.subtype}
                property='subtype'
                name='Subtype'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableSelectCell
                row={row}
                name='Default Growth Curve'
                data={row.defaultGrowthCurveId}
                property='defaultGrowthCurveId'
                menuItems={growthCurves}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                data={row.defaultStartingAge}
                property='defaultStartingAge'
                name='Default Starting Age'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                data={row.defaultStartingWeight}
                property='defaultStartingWeight'
                name='Default Starting Weight'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                data={row.defaultAvgWeightVariance}
                property='defaultAvgWeightVariance'
                name='Default Avg Weight'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableTableCell
                row={row}
                min={0}
                max={1}
                errorText='Default mortality rate must be at least 0 and cannot exceed 1'
                data={row.defaultMortalityRate}
                property='defaultMortalityRate'
                name='Default Mortality %'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='error'
                  startIcon={<CloseOutlined />}
                  onClick={() => removeAnimal(row)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </CustomizedTable>
    </section>
  );
}
