import "./sidebar.scss";

import { Logo } from "../../ui/logo/logo";
import { SidebarItem } from "./sidebar-item/sidebar-item";
import { ToggleButton } from "./toggle-button/toggle-button";
import cn from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import { menuItems } from "../../../config/sidebar-menu";

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ collapsed, setCollapsed }: SideBarProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const classNames = cn("sidebar", { "sidebar--collapsed": collapsed });

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={classNames}>
      <Logo className={`sidebar__logo ${collapsed && "sidebar__logo-min"}`} collapsed={collapsed} />

      {menuItems.map((item) => (
        <SidebarItem
          key={item.label}
          Icon={item.icon}
          label={item.label}
          link={item.link}
          collapsed={collapsed}
          expanded={expanded}
          setExpanded={setExpanded}
          expandable={item.expandable}
          expandableItems={item.expandableItems}
        />
      ))}

      <ToggleButton collapsed={collapsed} onClick={toggle} />
    </div>
  );
};
