import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./setup-bins-table.scss";
import { Barn, FeedType, Site } from "../../../../interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";

interface SetupBinesTableProps {
  headers: string[];
  rows: SetupBins[];
  loading: boolean;
  setBins: Dispatch<SetStateAction<SetupBins[]>>;
  onAddChanged: (row: SetupBins, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export interface SetupBins {
  id: string | null;
  subId: string | null;
  name: string;
  site: Site;
  siteId: string;
  barnId: string;
  barn: Barn;
  volume: number;
  inventory: number;
  feedTypeId: string;
  feedType: FeedType;
  date: string;
  isAdded: boolean;
  toDelete: boolean;
}

export function SetupBinesTable({
  headers,
  rows,
  loading,
  setBins,
  onAddChanged,
  isUnsavedChanges,
}: SetupBinesTableProps): JSX.Element {
  const [sites, setSites] = useState<Site[] | []>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleBinsSites();
  }, []);

  const handleBinsSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: SetupBins): void => {
    const data = [...rows];
    data.map((bin) => {
      if (bin.id === row.id) {
        bin = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setBins(data);
  };

  const removeBin = (row: SetupBins): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((bin) => bin.id !== copyRow?.id);
      setBins(data);
      onAddChanged(copyRow, true);
    }
  };

  const onChangeSite = (siteId: string, id: string): void => {
    const data = [...rows];
    const site = sites.find((site) => site.id === siteId);
    const barns = site ? site.barns : [];
    data.forEach((bin) => {
      if (bin.id === id && barns) {
        if (bin?.site?.barns) {
          bin.site.barns = barns;
        } else {
          bin.site = {
            ...bin.site,
            barns,
          };
        }
        if (barns[0]?.id) {
          bin.barnId = barns[0].id;
        }
        onSaveChanges(bin);
      }
    });
  };
  return (
    <section className='setup-bines-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Site'
              data={row.siteId}
              property='siteId'
              menuItems={sites}
              isUnsavedChanges={isUnsavedChanges}
              onChangeValue={onChangeSite}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Barn'
              data={row.barnId}
              property='barnId'
              isUnsavedChanges={isUnsavedChanges}
              menuItems={row?.site?.barns}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.volume}
              property='volume'
              name='Volume'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.inventory}
              property='inventory'
              name='Inventory'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button variant='contained' color='error' startIcon={<CloseOutlined />} onClick={() => removeBin(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
